import { Transition, Dialog } from "@headlessui/react";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { Formik, Field, Form} from "formik";
import { Fragment, useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { patchData, postData, postImage } from "../../../api";
import * as Yup from 'yup';

interface ParkingSpot{
    parkingSpotId:string,
    addressId:number,
    description: string,
    spotSize: number,
    photoUrl:string
  }
export default function AddPhotoModal(props:any){
    const {parking}=props
    const [selectedImage,setSelectedImage]=useState<File | null>(null)
    const [uploadedPhotoUrl, setUploadedPhotoUrl]=useState<String|null>(null)

  
  const parkingSpotPhotoJsonPatch = JSON.stringify([
    {
      "op": "add",
      "path": "/photoUrl",
      "value": `${uploadedPhotoUrl}`
    }
  ])
    
  const queryClient=useQueryClient()

  const mutation = useMutation(newParkingSpotPhoto=>postImage(newParkingSpotPhoto,`/ParkingSpot/SetPhoto?parkingspotid=${parking?.parkingSpotId}`),
  {
      onSuccess:(data)=>{
        queryClient.invalidateQueries("parkingSpots");
        setUploadedPhotoUrl(data?.imageUrl)
        // handlePhotoUrlUpdate(parkingSpotPhotoJsonPatch)
        props.setOpenClose()
      },
      onError:(error,variables,context)=>{
        alert(error)
      }
  });

  // const updateParkingSpot = useMutation(parkingSpotUpdatePatchDoc=>patchData(parkingSpotUpdatePatchDoc,parking?.parkingSpotId,"/ParkingSpot?parkingspotid="),
  // {
  //     onSuccess:()=>{
  //       queryClient.invalidateQueries("parkingSpots");
  //     },
  //     onError:(error,variables,context)=>{
  //       alert(error)
  //     }
  // });

  // const handlePhotoUrlUpdate=(data : any)=>{
  //   updateParkingSpot.mutate(data);
  // }
  const handleSubmit=(data:any)=>{
    mutation.mutate(data)
  }

  const handleFileInputChange=(event: React.ChangeEvent<HTMLInputElement>)=> 
  {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }

  }

  
  const initalValues = {file:null};
  const photoValidationSchema = Yup.object().shape({
      file: Yup.mixed().nullable().required("Please select a photo")
    });
    return(
        <Transition.Root show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={()=>props.setOpenClose()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
  
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 md:max-w-2xl sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                      <PlusCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-xl leading-6 font-medium text-gray-900">
                        Add Parking Spot Photo
                      </Dialog.Title>
                  
                      <div className="mt-2">
                          <div className="space-y-8 divide-y divide-gray-200">
                              <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                  <div>
                                      <div>
                                          <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                          Add the parking spot photo below
                                          </p>
                                      </div>
  
  
                                      <Formik 
                                        initialValues={initalValues} 
                                        validationSchema={photoValidationSchema}
                                        onSubmit={(values,{setSubmitting})=>{
                                          console.log("Attempting to submit parking spot photo", values);
                                                      
                                          handleSubmit(selectedImage)
  
                                          mutation.isError? setSubmitting(false):
                                          mutation.isError? alert('An error occured check log for details'):
                                          mutation.isLoading? setSubmitting(true):
                                          mutation.isSuccess? setSubmitting(false): setSubmitting(true);
  
                                            
  
                                        }}
                                      >
                                        {({isSubmitting,submitForm,handleChange,handleBlur,errors,touched,values,setSubmitting,setFieldValue}) =>
                                        (
                                          <Form onSubmit={(e) => {
                                              e.preventDefault();
                                             
                                              }
                                          }>
  
                                              <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
  
                                                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                                    <label htmlFor="cover-photo" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                        Photo
                                                    </label>
                                                    <div className="mt-1 sm:mt-0 sm:col-span-2" >
                                                        <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md ">
                                                          {selectedImage
                                                           ?

                                                           <img src={URL.createObjectURL(selectedImage)} alt="Selected parking spot "></img>

                                                           :
                                                           <>

                                                            <div className="space-y-1 text-center">
                                                                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                                    <path
                                                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                                <div className="flex text-sm text-gray-600">
                                                                <label
                                                                    htmlFor="photo"
                                                                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                                >
                                                                    <span>Upload a file</span>
                                                                    <input 
                                                                      id="photo" 
                                                                      name="file" 
                                                                      type="file" 
                                                                      accept="image/png, image/.jpeg"
                                                                      className="sr-only" 
                                                                      onChange={(event)=>{
                                                                        setFieldValue("file",event.currentTarget.files && event.currentTarget.files[0])
                                                                        handleFileInputChange(event)
                                                                      }}/>
                                                                  
                                                                </label>
                                                                <p className="pl-1">or drag and drop</p>
                                                                </div>
                                                                <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                                            </div>
                                                           </>

                                                          }
                                                            
                                                            {errors.file && touched.file && <div>{errors.file}</div>}

                                                        </div>
                                                    </div>
                                                  </div>
                                                   <div className="pt-5">
                                                        <div className="flex justify-end">
                                                          <button
                                                              type="button"
                                                              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                              onClick={()=>props.setOpenClose()}
                                                              //TODDO: Revoke Object URL for memory management when the modal is closed
                                                          >
                                                              Cancel
                                                          </button>
                                                          <button
                                                              type="submit"
                                                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                              onClick={submitForm}
                                                              disabled={isSubmitting}
                                                          >
                                                              Save
                                                          </button>
                                                        </div>
                                                    </div>
  
                                              </div>
                                          </Form>
                                        )}
                                      </Formik>
  
                                    
                                  </div>
                              </div>
  
                              
  
                             
                          </div>
                      </div>
                    </div>
                  </div>
                 
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
}