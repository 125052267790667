import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { getData } from "../../../api";
import ParkingsTable from "../../../components/ParkingsTable";
import TableHeaderActionButton from "../../../components/ParkingsTable/TableHeaderAction";
import TableRowActionButton from '../../../components/ParkingsTable/TableRowAction';
import PulseIndicator from '../../../components/PulseIndicator';
import SmallSpinner from '../../../components/SmallSpinner';
import TableContainer from '../../../components/TableContainer';
import useFetchData from "../../../hooks/useFetchData";
import AddParkingModal from '../AddParkingModal';



type ZipCodeDistrict={
  zipcode: number;
  city: string;
}

type ParkingsTableAddress={
  streetName: string;
  streetNumber: string;
  zipCodeDistrict: ZipCodeDistrict;
}

type ParkingSpot={
  parkingSpotId: number,
  address: ParkingsTableAddress;
  description:string;
  isRentedOut:boolean;
  
}

export default function FreeParkings() {

  const {isLoading, isError, data,  error} = useFetchData('availableParkingSpots',getData('/ParkingSpot/GetAvailableParkingspots?periodType=1'));
  console.log(data)
  // const refreshTable = React.useReducer(() => ({}), {})[1]
  const reserveSpot = ()=>{return false}


  const columnHelper = createColumnHelper<ParkingSpot>()
  const columns= [
    columnHelper.accessor(row => `${row.address?.streetName} ${row.address?.streetNumber}`, {
        id: 'address',
        maxSize:70,
        minSize:50,
        cell: data => 
            <span className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                {data.getValue()}
            </span>,
        header: () => <span>Address</span>,
        footer: data => data.column.id,
      }),
    columnHelper.accessor(row => `${row.address?.zipCodeDistrict?.city} ${row.address?.zipCodeDistrict?.zipcode}`, {
      id: 'area',
      maxSize:70,
      minSize:50,
      cell: data => 
          <span className="whitespace-nowrap px-3 py-4 text-sm  text-gray-500 hidden sm:table-cell ">
            {data.getValue()}
          </span>,
      header: () => <span>Area</span>,
      footer: data => data.column.id,
    }),
    columnHelper.accessor('description', {
      header: () => <span>Description</span>,
      maxSize: 300,
      cell: data =>
            <span className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell ">
              {data.getValue()}
            </span>,
      footer: data => data.column.id,
      
    }),
    columnHelper.accessor('isRentedOut', {
      header: ()=><span> Status</span>,
      cell: data => 
            <span className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"> 
              {data.getValue()?<PulseIndicator color={""} height={""} width={""}/> 
              : 
              <>
                <div className="flex flex-row">
                  <div className="flex items-center"> 
                    <span className="flex h-3 w-3">
                      <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-amber-400 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-3 w-3 bg-amber-500"></span>
                    </span>

                  </div>
                
                  <div>
                    <span className="whitespace-nowrap px-3 text-sm text-gray-500 hidden sm:table-cell ">
                      Pending
                    </span>
                  </div>
                </div>
                
              </>
              
              }
            </span>, 
      footer: data => data.column.id,
    }),
    
    columnHelper.display({
      header:()=><span> Status</span>,
      id: 'action',
      cell: (data: { row: any; }) => <TableRowActionButton title={"Reserve"} action={reserveSpot} row={data.row}/>
    }),
    
  ]

  if(isError){

      console.log(error);
      return(
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto">{"Error fetching data"}</div>
          </div>
        </>
      )
    }
  return(
      <>
         {
          isLoading?
          <>
            Loading Data <SmallSpinner/>
          </>
          :
          <>
              <TableContainer 
              title={"Free Parking Spots"} 
              description={"These are parking spots that are available and have not been occupied"} 
              data={data}
              isLoading={isLoading}
              noDataMessage="There are no parking spots that are available. Clear some spaces or add more parking spots"
              columns={columns}
              />
          </>
        }
        
        

            {/* <AddParkingModal/> */}
      </>
  )
    
}