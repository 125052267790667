import { TicketIcon } from "@heroicons/react/outline";
import { useState} from "react";
import { useParams } from "react-router-dom"
import { deleteData, getData, postData } from "../../../api";
import SmallSpinner from "../../../components/SmallSpinner";
import useFetchData from "../../../hooks/useFetchData";
import AddRentToDriverModal from "./AddRentToDriverModal";
import footballEventImage from "../../../assets/temp/football-min.png"
import concertEventImage from "../../../assets/temp/concert-min.png"
import otherEvent from "../../../assets/temp/otherevent-min.png"
import { ArrowRightIcon, LockClosedIcon, XIcon } from "@heroicons/react/solid";
import { useQueryClient, useMutation } from "react-query";
import ViewParkingSpotRentsModal from "./ViewParkingSpotRentsModal";


interface Address{
    addressId:number;
    streetName: string;
    streetNumber: string;
    zipCode: number;
    area: string;
  }
interface ParkingSpot{
    pSpotActive:number,
    description: string,
    spotSize: number
    address: Address,
  }
interface RentPeriod{
    rentPeriodId:number,
    rentPeriodStart:string
    rentPeriodEnd:string,
    rentPeriodType:Number,
    parkingSpot:ParkingSpot,
    monthlyRentPeriodType: 0,
    moFiStartTime: TimeRanges|null,
    moFiEndTime: TimeRanges|null,
    satStart: TimeRanges|null,
    satEnd: TimeRanges|null,
    sunStart: TimeRanges|null,
    sunEnd: TimeRanges|null,
    isRentedOut:boolean
  }

export default function EventParkingSpots(){
    const{id,eventName,eventVenue,price,category}=useParams();
    console.log(id);
    const [isOpenRentOutModal, setOpenRentOutModal] = useState(false);
    const [isOpenViewParkingSpotRentsModal, setOpenViewParkingSpotRentsModal] = useState(false);
    const {isLoading, isError, data,  error} = useFetchData('eventRentPeriods',getData(`/RentPeriod/GetAllRentPeriodsByEvent?eventId=${id}`));
    const [selectedEventRentPeriod,setSelectedEventRentPeriod]=useState({});
    const [selectedParkingSpot,setSelectedParkingSpot]=useState({});
    const toggleRentOutModal =()=>{
        setOpenRentOutModal(!isOpenRentOutModal);
    }
    const toggleViewParkingSpotRents =()=>{
        setOpenViewParkingSpotRentsModal(!isOpenViewParkingSpotRentsModal);
    }

    
    
    return (
        <>
            <div className="max-w-7xl  px-4 sm:px-6 lg:px-8 pt-6">
                <header>
                    <h1 className="text-xl font-bold leading-tight text-gray-900">Event Parkings</h1>
                </header>

                <div className="my-6  rounded border-2  border-pink-500 grid grid-cols-2 ">
                    <div className="col-span-1 p-6 ">
                        <span> <TicketIcon className="h-8 text-blue-700"/></span>
                        <h1 className="text-3xl font-semibold"> {eventName}</h1>
                        <h1 className="text-lg"> {eventVenue}</h1>
                    </div>

                    <div className="col-span-1 max-h-36">
                        <img 
                            src={eventImage(category)}
                            alt="event" 
                            className="rounded-r h-full w-full object-cover" 
                        
                                />
                    </div>
                    
                </div>

                <hr className="my-8 h-px bg-gray-200 border-1 dark:bg-gray-700"></hr>

                <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 my-8">
                    {isLoading &&  <div>Loading Data <SmallSpinner/></div>}
                    {
                        isError? alert(error) : console.log("Successfuly got event rent periods")
                    }
                    {data?.map((rentPeriod :RentPeriod) => (
                        <div 
                            key={rentPeriod.rentPeriodId}
                            
                        >

                       
                         
                            <div 
                                key={rentPeriod.rentPeriodId} 
                                className="p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md "
                            
                            >
                                    
                                <div>
                                    <h5 className="mb-1 text-xl font-bold tracking-tight text-gray-700">{`${rentPeriod?.parkingSpot?.address?.streetName}, ${rentPeriod?.parkingSpot?.address?.streetNumber}`}</h5>
                                    <h5 className="mb-2 text-lg font-semibold tracking-tight text-gray-600">{`${rentPeriod?.parkingSpot?.address.area}`}</h5>
                                </div>
                                <p className="mb-3  text-gray-700 line-clamp-2 max-h-20">{rentPeriod.parkingSpot.description}</p>
                                {rentPeriod.isRentedOut?
                                    <>
                                        <div className="grid grid-flow-row ">
                                            <button 
                                                className=" py-2 px-2 text-sm font-medium text-center text-white bg-green-500 rounded "
                                                onClick={()=>{
                                                    setSelectedParkingSpot(rentPeriod?.parkingSpot)
                                                    toggleViewParkingSpotRents()
                                                    }}
                                            >
                                                Occupied  
                                            </button>
                                            
                                        </div>

                                    </>
                                    :
                                    <>
                                        <div className="grid grid-flow-row ">
                                            <button className=" py-2 px-4 text-sm font-medium text-center text-white bg-blue-700 rounded hover:bg-blue-800 focus:ring-2 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" 
                                                onClick={()=>{
                                                    setSelectedEventRentPeriod(rentPeriod)
                                                    toggleRentOutModal()
                                                    }}>
                                                    Rent Out
                                                    
                                            </button>
                                        </div>

                                    </>
                                }
                            
                            </div>

                        </div>
                    ))}
                    </div>
                
            </div>
            {isOpenRentOutModal &&
            <AddRentToDriverModal isOpen={isOpenRentOutModal} setOpenClose={toggleRentOutModal} eventRentPeriod={selectedEventRentPeriod} price={price}></AddRentToDriverModal>}
            {isOpenViewParkingSpotRentsModal &&
            <ViewParkingSpotRentsModal isOpen={isOpenViewParkingSpotRentsModal} setOpenClose={toggleViewParkingSpotRents} parkingSpot={selectedParkingSpot} eventRentPeriod={selectedEventRentPeriod} eventName={eventName}></ViewParkingSpotRentsModal>}
        </>
    )
}

export const eventImage =(eventCategory:any)=>{
    let img;
    switch (eventCategory){
        case 0:
        img= footballEventImage
        break

        case 1:
        img= concertEventImage
        break
        
        case 2:
        img= otherEvent
        break

        default:
        img=otherEvent
        break
    }

    return img
}
