import { Form, Formik} from "formik";
import { addAddress } from "../../../api";
import usePostData from "../../../hooks/usePostData";
import * as Yup from 'yup';


interface Address{
    streetName: string;
    streetNumber: string;
    zipCode: number;
    area: string;
}

export default function AddAddressForm(){

    const initialValues:Address = {streetName: "", streetNumber: "", zipCode: 0, area:""};
    const addressValidationSchema = Yup.object({
        streetName: Yup.string()
         .max(15, 'Must be 15 characters or less')
         .required('Required'),
       streetNumber: Yup.string()
         .max(10, 'Must be 20 characters or less')
         .required('Required'),
       zipCode: Yup.number().required('Required'),
       Area: Yup.string().required('Required'),

    });

    
 
    return (
        <>
            <div className={'pt-4'}>
                <div className="grid justify-items-start">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">Address Details</h4>
                    <p className="mt-1 text-sm text-gray-500">You are creating a new address, enter the details below.</p>
                    </div>

                    <Formik 
                        initialValues={initialValues} 
                        onSubmit={(values,{setSubmitting}) =>
                            {
                               
                            } 
                        }
                        validationSchema={addressValidationSchema}
                        
                    >

                       {({isSubmitting,submitForm,handleChange,handleBlur,errors,touched,values,setSubmitting}) =>(
                            <Form onSubmit={ (e)=>{
                                 e.preventDefault();
                                 console.log("Attempting to submit new address", values);
                                
                               
                                 // eslint-disable-next-line react-hooks/rules-of-hooks
                                 const mutation = usePostData(addAddress(values));
                                 mutation.isError? setSubmitting(false):
                                 mutation.isError? alert('An error occured check log for details'):
                                 mutation.isLoading? setSubmitting(true):
                                 mutation.isSuccess? setSubmitting(false): setSubmitting(true);
                                 
                                
                                } }>
                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-3 grid justify-items-start">
                                        <label htmlFor="streetName" className="block text-sm font-medium text-gray-700">
                                        Street Name
                                        </label>
                                        <div className="mt-1">
                                        <input
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type="text"
                                            name="streetName"
                                            id="streetName"
                                            autoComplete="address-line1"
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                        {errors.streetName && touched.streetName && <div>{errors.streetName}</div>}
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3 grid justify-items-start">
                                        <label htmlFor="streetNumber" className="block text-sm font-medium text-gray-700">
                                        Street Number
                                        </label>
                                        <div className="mt-1">
                                        <input
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type="number"
                                            name="streetNumber"
                                            id="streetNumber"
                                            autoComplete="address-line1"
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                        {errors.streetNumber && touched.streetNumber && <div>{errors.streetNumber}</div>}

                                        </div>
                                    </div>

                                    <div className="sm:col-span-3 grid justify-items-start">
                                        <label htmlFor="zipCode" className="block text-sm font-medium text-gray-700">
                                        ZIP Code
                                        </label>
                                        <div className="mt-1">
                                        <input
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type="number"
                                            name="zipCode"
                                            id="zipCode"
                                            autoComplete="postal-code"
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                        {errors.zipCode && touched.zipCode && <div>{errors.zipCode}</div>}

                                        </div>
                                    </div>

                                    <div className="sm:col-span-3 grid justify-items-start">
                                        <label htmlFor="area" className="block text-sm font-medium text-gray-700">
                                        Area
                                        </label>
                                        <div className="mt-1">
                                        <input
                                            onChange={handleChange}
                                            type="text"
                                            name="area"
                                            id="area"
                                            autoComplete="address-level2"
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                        {errors.area && touched.area && <div>{errors.area}</div>}

                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-end mt-4">
                                
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Save
                                    </button>
                                </div>

                            </Form>
                        )}
                        
                    </Formik>
                    
                 
            </div>

        </>
    )
}