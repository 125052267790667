
interface RowAction{
    title: string;
    action: Function;
    row:any;
}
export default function TableRowActionButton(props: RowAction){



    return(
        <>
            <div className="flex flex-col items-center">
                <button
                    type="button"
                    className="inline-flex items-center px-2 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={()=>props.action}
                >
                    {props.title}
                </button>

            </div>
           
        </>
    )

}