import React, { Fragment, useEffect, useState } from 'react'
import * as Yup from 'yup';

import { Dialog, Transition } from '@headlessui/react'
import { PlusCircleIcon } from '@heroicons/react/outline'
import useFetchData from '../../../hooks/useFetchData'
import {getData, postData } from '../../../api'
import { Field, Form, Formik } from 'formik'
import usePostData from '../../../hooks/usePostData'
import { useMutation, useQueryClient } from 'react-query';

type User={
  email?:string;
  firstName?:string;
  lastName?:string;
  phoneNumber?:string;
  userId?:string;
  active?:boolean;
  address?:string;
  zipcode?:number;
  emailConfirmed?:boolean;
}

interface Driver{
  driverId?:number,
  user?:User,
  vehicles?: Array<Vehicle>
}


interface Rent{
  userID: string,
  parkingSpotId: number,
  vehicleId: number,
  startTime: string,
  endTime: string,
  price: number
  type: number
  rentPeriodId: number
}

interface Vehicle{
      vehicleId:number,
      nickName: string,
      licensePlate: string,
      size: number,
      driverId: string,
}

export default function AddRentToDriverModal(props: any) {

    const {price,eventRentPeriod}=props;
    console.log(eventRentPeriod)
    const {isLoading, isError, data,  error} = useFetchData('allParkingSpots',getData('/ParkingSpot/GetAllParkingSpots'));
    const drivers = useFetchData('allDrivers',getData('/Driver/GetAllDrivers'));
    const [selectedDriver,setSelectedDriver]=useState<any>("");
    const [driverVehicles,setDriverVehicles]=useState([])

    useEffect(()=>{
      if(selectedDriver){
       const vehicles =JSON.parse(selectedDriver).vehicles;
       setDriverVehicles(vehicles)
      }
   
    },[selectedDriver])

  const validationSchema = Yup.object({
     userID: Yup.string().required('Required'), 
     vehicleId: Yup.number().required('Required'),
    })

    const initialValues:Rent = {
      userID: "",
      parkingSpotId: eventRentPeriod?.parkingSpot?.parkingSpotId,
      vehicleId: 0,
      price:price,
      type:2,
      rentPeriodId:eventRentPeriod?.rentPeriodId,
      startTime: eventRentPeriod?.rentPeriodStart,
      endTime:eventRentPeriod?.rentPeriodEnd,
      };
        

  const queryClient=useQueryClient()

  const mutation = useMutation(newRentForDriver=>postData(newRentForDriver,'/RentParkingSpot/AddRentToDriver'),{
      onSuccess:()=>{
        queryClient.invalidateQueries("eventRentPeriods")
        props.setOpenClose()
      }
  });

  const handleSubmit=(data:any)=>{
    mutation.mutate(data)
  }


  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={()=>props.setOpenClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 md:max-w-2xl sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <PlusCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-xl leading-6 font-medium text-gray-900">
                      Add Event
                    </Dialog.Title>
                
                    <div className="mt-2">
                        <div className="space-y-8 divide-y divide-gray-200">
                            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                <div>
                                    <div>
                                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                        Select the parking spot to assign to this event
                                        </p>
                                    </div>

                                    <Formik 
                                      initialValues={initialValues} 
                                      onSubmit={(values,{setSubmitting}) =>
                                          {
                                            console.log("Attempting to add new rent for a driver", values);
                                                    
                                                   handleSubmit(values)

                                                   mutation.isError? setSubmitting(false):
                                                   mutation.isError? alert('An error occured check log for details'):
                                                   mutation.isLoading? setSubmitting(true):
                                                   mutation.isSuccess? setSubmitting(false): setSubmitting(true);
                                          } 
                                      }
                                      validationSchema={validationSchema}
                                      
                                  >

                                    {({isSubmitting,submitForm,handleChange,handleBlur,errors,touched,values,setSubmitting}) =>(
                                          <Form onSubmit={ (e)=>{
                                              e.preventDefault();
                                            
                                              
                                            
                                              } }>
                                              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                  <div className="sm:col-span-6 grid justify-items-start">
                                                  <>
                                                      <label htmlFor="drivers"className="font-semibold test-gray-700">Driver</label>
                                                      <Field
                                                          as="select"
                                                          title='drivers'
                                                          id="drivers"
                                                          name="userID"
                                                          type="number"
                                                          onChange={(e:React.ChangeEvent<any>)=>{
                                                            handleChange(e)
                                                            setSelectedDriver(e.target.options[e.target.selectedIndex].dataset.driver)
                                                            
                                                          }}
                                                          onBlur={handleBlur}
                                                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                      >
                                                          <option key={'no-driver'} >Select the driver to rent out to</option>
                                                          
                                                          {drivers?.data?.map((driver:Driver) => (
                                                              <option key={driver?.driverId} value={driver?.driverId} data-driver={JSON.stringify(driver)}>{ `${driver?.user?.firstName} ${driver?.user?.lastName} ${" of "} ${driver?.user?.email}` }</option>
                                                          ))}
                                                      </Field>

                                                      {errors.userID && touched.userID && <div className='text-xs text-red-500'>{errors.userID}</div>}

                                                      </>
                                                  </div>

                                                  
                                              </div>
                                              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                  <div className="sm:col-span-6 grid justify-items-start">
                                                  <>
                                                      <label htmlFor="driver-vehicles"className="font-semibold text-gray-700"> Vehicle </label>
                                                      <Field
                                                          as="select"
                                                          title='driver-vehicles'
                                                          id="driver-vehicles"
                                                          name="vehicleId"
                                                          type="number"
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                      >
                                                          <option key={'no-vehicle'}>Select the vehicle being parked </option>
                                                          {selectedDriver?
                                                            
                                                            driverVehicles?.map((vehicle:Vehicle) => (
                                                              <option key={vehicle?.vehicleId} value={vehicle?.vehicleId}>{ `${vehicle?.nickName} ${vehicle?.licensePlate}` }</option>
                                                              ))
                                                            :<></>
                                                          }
                                                          
                                                      </Field>

                                                      {errors.vehicleId && touched.vehicleId && <div>{"Select the vehicle being parked"}</div>}

                                                      </>
                                                  </div>

                                                  
                                              </div>
                                              <div className="flex justify-end mt-4">
                                              
                                                  <button
                                                      type="submit"
                                                      disabled={isSubmitting}
                                                      onClick={submitForm}
                                                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                  >
                                                      Add Rent
                                                  </button>
                                              </div>

                                          </Form>
                                      )}
                        
                                    </Formik>
                                        

                                  
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
               
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}


