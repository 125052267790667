import React, { Fragment } from 'react'
import * as Yup from 'yup';
import logo from '../../../../assets/logos/logo.svg'

import { Dialog, Transition } from '@headlessui/react'
import { useMutation, useQueryClient } from 'react-query';
import { getData, postData } from '../../../../api';
import { Field, Form, Formik } from 'formik';
import useFetchData from '../../../../hooks/useFetchData';


export default function AddDriverModal(props: any) {

    const {isLoading, isError, data,  error} = useFetchData('users',getData('/Admin/GetAllUsers'));

    interface Driver{
        id: string,
      
       
    }
    
    type User={
        email:string;
        firstName:string;
        lastName:string;
        phoneNumber:string;
        userId:string;
        active:boolean;
        address:string;
        zipcode:number;
        emailConfirmed:boolean;
    }



  const initialValues:Driver = {
    id: ""
  };
    
  const ownerValidationSchema = Yup.object({
     id: Yup.string().required("Required")
   
    })

  const queryClient=useQueryClient()

  const mutation = useMutation(newDriver=>postData(newDriver,'/Driver'),{
      onSuccess:()=>{
        queryClient.invalidateQueries("allDrivers")
        props.setOpenClose()
      },
      onError:(error,variables,context)=>{
        alert(error)
      }
  });

  const handleSubmit=(data:any)=>{
    mutation.mutate(data)
  }


  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={()=>props.setOpenClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 md:max-w-2xl sm:w-full sm:p-6">
                <div>
                  
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-xl leading-6 font-medium text-gray-900">
                      
                    </Dialog.Title>
                
                    <div className="mt-2">
                        <div className="space-y-8 divide-y divide-gray-200">
                            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                <div>
                                    
                                        <Formik 
                                            initialValues={initialValues} 
                                            onSubmit={(values,{setSubmitting}) =>
                                                {
                                                  console.log(values)
                                                   console.log("Attempting to register new driver", values);
                                                    
                                                   handleSubmit(values)

                                                   mutation.isError? setSubmitting(false):
                                                   mutation.isError? alert('An error occured check log for details'):
                                                   mutation.isLoading? setSubmitting(true):
                                                   mutation.isSuccess? setSubmitting(false): setSubmitting(true);
                                                } 
                                            }
                                            validationSchema={ownerValidationSchema}
                                            
                                        >

                                          {({isSubmitting,submitForm,handleChange,handleBlur, handleSubmit, errors,touched,values,setSubmitting}) =>(
                                                <Form onSubmit={ (e)=>{
                                                    e.preventDefault();
                                                   
                                                    
                                                    
                                                    }}>

                                                      <div className="flex min-h-full flex-col justify-start py-12 sm:px-6 lg:px-8">
                                                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                                                          <img
                                                              className="mx-auto h-8 w-auto"
                                                              src={logo}
                                                              alt="ParkShare"
                                                          />
                                                          <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900">Select and add new driver</h2>
                                                          <p className="mt-2 text-center text-sm text-gray-600">
                                                            
                                                          </p>
                                                        </div>

                                                        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
                                                          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                                                              <div className="space-y-6">

                                                                <div>
                                                                  <div className='flex justify-items-start'>
                                                                    <label htmlFor="user-id" className="block text-sm font-medium  text-gray-700">
                                                                    Driver
                                                                    </label>
                                                                  </div>
                                                                    
                                                                    <div className="mt-1">
                                                                    <Field
                                                                            as="select"
                                                                            id="user-id"
                                                                            name="id"
                                                                            type="string"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                                          >
                                                                            <option key="ParkShare" value="ParkShare"> Select a user to make a driver</option>
                                                                            {data?.map((user:User)=>(
                                                                              <option key={user?.userId} value={user?.userId}>{`${user.firstName} ${user.lastName} `}  {`${user.email}`}</option>

                                                                            ))}
                                                                          </Field>
                                                                          {errors.id && touched.id && <div>{errors.id}</div>}
                                                                    </div>
                                                                </div>
                                                            

                                                                <div>
                                                                  
                                                                    <button
                                                                    type="submit"
                                                                    className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                    onClick={submitForm}
                                                                    disabled={isSubmitting}
                                                                    >
                                                                    Add Driver
                                                                    </button>
                                                                </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>

                                                    </Form>
                                          )}
                                        </Formik>

                                  
                                </div>
                            </div>

                            

                           
                        </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}




