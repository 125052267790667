import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { CalendarIcon, DotsCircleHorizontalIcon, LocationMarkerIcon, PlusCircleIcon } from '@heroicons/react/outline'
import useFetchData from '../../../hooks/useFetchData'
import {deleteData, getData} from '../../../api'
import { useMutation, useQueryClient } from 'react-query';
import format from 'date-fns/format'
import SmallSpinner from '../../../components/SmallSpinner'

type User={
  email?:string;
  firstName?:string;
  lastName?:string;
  phoneNumber?:string;
  userId?:string;
  active?:boolean;
  address?:string;
  zipcode?:number;
  emailConfirmed?:boolean;
}

interface Driver{
  driverId?:number,
  user?:User,
  vehicles?: Array<Vehicle>
}


interface Rent{
  userID: string,
  parkingSpotId: number,
  vehicleId: number,
  startTime: string,
  endTime: string,
  price: number
  type: number
  rentPeriodId: number
  rentId:number,
  firstName:string,
  lastName:string,
  phoneNumber:string,
  licensePlate:string,
  nickName:string,
}

interface Vehicle{
      vehicleId:number,
      nickName: string,
      licensePlate: string,
      size: number,
      driverId: string,
}

function classNames(...classes: (string | boolean | undefined)[]) {
  return classes.filter(Boolean).join(' ')
}


export default function ViewParkingSpotRentsModal(props: any) {
  const{parkingSpot,eventName}=props

  const {isLoading, isError, data,  error} = useFetchData('rents',getData(`/RentParkingSpot/GetRentsFromSpecificParkingSpot/?parkingspotId=${parkingSpot?.parkingSpotId}`));

  console.log(data)

  const queryClient=useQueryClient()

  const mutation = useMutation(rentId=>deleteData(`/RentParkingSpot/RemoveRentFromDriver?rentId=${rentId}`),{
      onSuccess:()=>{
        queryClient.invalidateQueries("eventRentPeriods")
        alert("Rent removed successfully")
      },
      onError:()=>{
          console.log(error)
      }
  });

  const cancelRent=(data:any)=>{
    mutation.mutate(data)
  }


  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={()=>props.setOpenClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 md:max-w-2xl sm:w-full sm:p-6">
                <div>
                 
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-xl leading-6 font-medium text-gray-900">
                      {`Rents for ${parkingSpot.address.streetName} ${parkingSpot.address.streetNumber}`}
                    </Dialog.Title>
                
                    <div className="mt-2">
                        <div className="space-y-8 divide-y divide-gray-200">
                            <div className="space-y-8 divide-y divide-pink-500 sm:space-y-5">
                              <div>
                                  <div className=''>
                                      <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                      These are the rents for this parking spot on this event
                                      </p>
                                  </div>
                                </div>

                                <div>
                                {isLoading?
                                  <>
                                    Loading Rent Data <SmallSpinner/>
                                  </>
                                  :
                                  !data?
                                  <>
                                    <p className="ml-8">{`${'This spot has no rents for '+eventName}`}</p>
                                  </>
                                  :
                                    <ol className="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
                                      {data?.map((rent:Rent) => (
                                        <li key={rent.rentId} className="relative flex space-x-6 py-6 xl:static">
                                          <img src={'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'} alt="" className="h-14 w-14 flex-none rounded-full" />
                                          <div className="flex-auto">
                                            <h3 className="text-base text-left  font-semibold text-gray-900">{`${rent.firstName + ' '+ rent.lastName}`} &#x2022; {`${rent.phoneNumber}` }</h3>
                                            <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                                              <div className="flex items-start space-x-3">
                                                <dt className="mt-0.5">
                                                  <span className="sr-only">Date</span>
                                                  <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                </dt>
                                                <dd>
                                                  <time dateTime={rent.startTime}>
                                                    {format(new Date(rent.startTime),`MMMM dd yyyy`)} at {format(new Date(rent.startTime),`HH:mm`)} to {format(new Date(rent.endTime),`MMMM dd yyyy HH:mm`)}
                                                  </time>
                                                </dd>
                                              </div>
                                              <div className="mt-2 flex items-start space-x-3 xl:mt-0 xl:ml-3.5 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                                                <dt className="mt-0.5">
                                                  <span className="sr-only">Location</span>
                                                  <LocationMarkerIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                </dt>
                                                <dd>{`${rent.nickName +" ("+rent.licensePlate+")"}`}</dd>
                                              </div>
                                            </dl>
                                          </div>
                                          <Menu as="div" className="absolute top-6 right-0 xl:relative xl:top-auto xl:right-auto xl:self-center">
                                            <div>
                                              <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
                                                <span className="sr-only">Open options</span>
                                                <DotsCircleHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                                              </Menu.Button>
                                            </div>

                                            <Transition
                                              as={Fragment}
                                              enter="transition ease-out duration-100"
                                              enterFrom="transform opacity-0 scale-95"
                                              enterTo="transform opacity-100 scale-100"
                                              leave="transition ease-in duration-75"
                                              leaveFrom="transform opacity-100 scale-100"
                                              leaveTo="transform opacity-0 scale-95"
                                            >
                                              <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="py-1">
                                                  {/* <Menu.Item>
                                                    {({ active }) => (
                                                      <button
                                                        
                                                        className={classNames(
                                                          active ? 'bg-gray-100 text-gray-900 w-full' : 'text-gray-700',
                                                          'block px-4 py-2 text-sm')}
                                                      >
                                                        Edit
                                                      </button>
                                                    )}
                                                  </Menu.Item> */}
                                                  <Menu.Item>
                                                    {({ active }) => (
                                                      <button
                                                        className={classNames(
                                                          active ? 'bg-gray-100 text-gray-900 w-full' : 'text-gray-700',
                                                          'block px-4 py-2 text-sm'
                                                        )}
                                                        onClick={()=>{cancelRent(rent.rentId)}}
                                                      >
                                                        Cancel
                                                      </button>
                                                    )}
                                                  </Menu.Item>
                                                </div>
                                              </Menu.Items>
                                            </Transition>
                                          </Menu>
                                        </li>
                                      ))}
                                    </ol>
                                  }
                                  </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
               
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}


