import { ArrowCircleLeftIcon, ArrowCircleRightIcon} from '@heroicons/react/solid';
import { createColumnHelper } from '@tanstack/react-table';
import { format, isPast } from 'date-fns';
import React, { useState } from 'react';
import { getData } from "../../../api";
import TableRowActionButton from '../../../components/ParkingsTable/TableRowAction';
import SmallSpinner from '../../../components/SmallSpinner';
import TableContainer from '../../../components/TableContainer';
import useFetchData from "../../../hooks/useFetchData";
import AddEventModal from '../AddEventModal';



type ZipCodeDistrict={
  zipcode: number;
  city: string;
}

interface Venue {
  venuId:number;
  address: string;
  description:string;
  name:string;
  latitude: string;
  longitude:string;
  zipCodeDistrict:ZipCodeDistrict;
  area:string

}

interface Event {
  eventId: number,
  description:string;
  name:string;
  eventCategory: number;
  price : number;
  startTime:string;
  endTime:string;
  venue :Venue;
  numberOfSpots: number;
  
}

export default function AllEvents() {

  const [isOpenAddEventModal,setOpenAddEventModal]=useState(false);
  const [isOpenEventParkingsModal,setOpenEventsParkingModal]=useState(false);
  const [selectedEvent,setSelectedEvent] =useState({})

  const {isLoading, isError, data,  error} = useFetchData('events',getData('/Event/GetAllEvents'));
  
  //const refreshTable = React.useReducer(() => ({}), {})[1]
  const reserveSpot = ()=>{console.log("Whaat")};
  const toggleAddEventModal=()=>{
    setOpenAddEventModal(!isOpenAddEventModal)
  };
  const toggleEventParkingSpotsModal=()=>{
    setOpenEventsParkingModal(!isOpenEventParkingsModal)
  };


  const columnHelper = createColumnHelper<Event>();
  const columns= [
    columnHelper.accessor('name', {
        id: 'name',
        maxSize:150,
        minSize:70,
        cell: data => 
            <span className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 lg:pl-8">
                {data.getValue()}
            </span>,
        header: () => <span>Name</span>,
        footer: data => data.column.id,
      }),
      columnHelper.accessor(row=>`${row.venue?.name}`, {
        id: 'Venue',
        maxSize:150,
        minSize:70,
        cell: data => 
            <span className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 lg:pl-8">
                {data.getValue()}
            </span>,
        header: () => <span>Venue</span>,
        footer: data => data.column.id,
      }),
      columnHelper.accessor(row=>`${row.venue?.address}`, {
        id: 'address',
        maxSize:150,
        minSize:70,
        cell: data => 
            <span className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 lg:pl-8">
                {data.getValue()}
            </span>,
        header: () => <span>Address</span>,
        footer: data => data.column.id,
      }),
    columnHelper.accessor(row => `${row.venue?.zipCodeDistrict?.city} ${row.venue?.zipCodeDistrict?.zipcode}`, {
      id: 'area',
      
      cell: data => 
          <span className="whitespace-nowrap px-3 py-4 text-xs  text-gray-500 hidden sm:table-cell ">
            {data.getValue()}
          </span>,
      header: () => <span>Area</span>,
      footer: data => data.column.id,
    }),

    columnHelper.accessor('startTime', {
        id: 'startTime',
       
        cell: data => 
            <span className="whitespace-nowrap px-3 py-4 text-xs  text-gray-500 hidden sm:table-cell ">
              {format(new Date(data.getValue()),'MMM, dd, yyyy HH:mm')}
            </span>,
        header: () => <span>Start</span>,
        footer: data => data.column.id,
    }),
    columnHelper.accessor('endTime', {
    id: 'endTime',
   
    cell: data => 
        <span className="whitespace-nowrap px-3 py-4 text-xs  text-gray-500 hidden sm:table-cell ">
            {format(new Date(data.getValue()),'MMM, dd, yyyy HH:mm')}
        </span>,
    header: () => <span>End</span>,
    footer: data => data.column.id,
    }),
    columnHelper.accessor('endTime', {
      id: 'status',
     
      cell: data => 
          <span className="whitespace-nowrap px-3 py-4 text-xs  text-gray-500 hidden sm:table-cell ">
              {
                isPast(new Date(data.getValue()))
                ? 
                  <>
                    <ArrowCircleLeftIcon className="h-4"/> Past
                  </>
                : <>
                    <ArrowCircleRightIcon className="h-4"/> Upcoming
                  </>}
          </span>,
      header: () => <span>Status</span>,
      footer: data => data.column.id,
      }),
      columnHelper.accessor('numberOfSpots', {
        id: 'Spots',
        maxSize:150,
        minSize:70,
        cell: data => 
            <span 
              className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-grey-500 sm:pl-6 lg:pl-8 "
              onClick={()=>toggleEventParkingSpotsModal() }
            >
                {data.getValue()}
            </span>,
        header: () => <span>Spots</span>,
        footer: data => data.column.id,
      }),
    
    // columnHelper.accessor('isRentedOut', {
    //   header: ()=><span> Status</span>,
    //   cell: data => 
    //         <span className="whitespace-nowrap py-4 text-right text-sm font-medium"> 
    //           {data.getValue()?<PulseIndicator color={""} height={""} width={""}/> 
    //           : 
    //           <>
    //             <div className="flex flex-col items-center ">
    //                 <div className="flex-row">
    //                     <div className="flex items-center"> 
    //                         <span className="flex h-3 w-3">
    //                             <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-green-400 opacity-75"></span>
    //                             <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
    //                         </span>

    //                         <div>
    //                             <span className="whitespace-nowrap pl-3 text-xs text-gray-500 hidden sm:table-cell ">
    //                             Active
    //                             </span>
    //                         </div>
    //                     </div>
                
                       

    //                 </div>
                 
    //             </div>
                
    //           </>
              
    //           }
    //         </span>, 
    //   footer: data => data.column.id,
    // }),
    // columnHelper.accessor('eventCategory', {
    //     id: 'monthlyRentPeriodType',
    //     cell: data => 
    //     <span className="whitespace-nowrap py-4 text-right text-xs font-medium"> 
    //         {data.getValue()
    //         ?
            
    //             <>
    //                 <div className="flex flex-col items-center ">
    //                     <div className="flex-row">
    //                         <div className="flex items-center"> 
    //                            {
    //                             data.getValue()==3
    //                             ?
    //                                 <>
    //                                     <CalendarIcon className="h-4 text-indigo-500"/>
    //                                     <div>
    //                                         <span className="whitespace-nowrap text-xs text-gray-500 hidden sm:table-cell ">
    //                                             Monthly
    //                                         </span>
    //                                     </div>
    //                                 </>
                                   
                                    
    //                             :data.getValue()==2
    //                             ?
    //                                 <>
    //                                     <TicketIcon className="h-4 text-indigo-500"/>
    //                                     <div>
    //                                         <span className="whitespace-nowrap pl-3 text-xs text-gray-500 hidden sm:table-cell ">
    //                                             Event
    //                                         </span>
    //                                     </div>
    //                                 </>
                                    
    //                             :data.getValue()==1
    //                             ?
    //                                 <>
    //                                     <ClockIcon className="h-4 text-indigo-500"/>
    //                                     <div>
    //                                         <span className="whitespace-nowrap pl-3 text-xs text-gray-500 hidden sm:table-cell ">
    //                                             Time
    //                                         </span>
    //                                     </div>
    //                                 </>
    //                             :
    //                             null

                                
    //                            }

                               
    //                         </div>
                    
                            

    //                     </div>
                    
    //                 </div>
    //             </> 
    //         : 
    //            null
    //         }
    //     </span>, 
    //     header: () => <span>Type</span>,
    //     footer: data => data.column.id,
    // }),
    
    columnHelper.display({
      header:()=><span> Action</span>,
      id: 'action',
      cell: (data =>
        <>
          <div className="flex flex-col items-center">
              <button
                  type="button"
                  className="inline-flex items-center px-2 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={()=>{
                    setSelectedEvent(data.row.original)
                    toggleEventParkingSpotsModal()
                    
                  }}
              >
                  Add Parking
              </button>
              

          </div>
       
        </>
        )
    }),
    
  ]
  
  if(isError){

      console.log(error);
      return(
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto">{"Error fetching data"}</div>
          </div>
        </>
      )
    }
  return(
      <>
        <div className="pt-6">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">All Events</h1>
              <p className="mt-2 text-sm text-gray-700">These are all events past and present that we offer parking for</p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  onClick={toggleAddEventModal}
              >
                 Add Event
              </button>
            </div>
          </div>
        </div>
        {
          isLoading?
          <>
            Loading Data <SmallSpinner/>
          </>
          :
          <>
             <TableContainer
              title={"All Events"} 
              description={"These are all events past and present that we offer parking for"} 
              data={data}
              isLoading={isLoading}
              noDataMessage={'Event data not found'}
              columns={columns}
              />
          </>
        }
         
            { isOpenAddEventModal && 
              <AddEventModal isOpen={isOpenAddEventModal} setOpenClose={toggleAddEventModal}/>
            }
           
      </>
  )
    
}