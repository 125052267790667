import TabsContainer from "../../../components/TabsContainer"

const tabs = [
    { name: 'All Owners', to: '/owner-management/all-owners', current: false },
    // { name: 'Active Owners', to: '/owner-management/active-owners', count: '6', current: false },
    // { name: 'All Events', to: '/event-management/all-events', count: '4', current: false },
  ]
  
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }
export default function OwnerManagementTabs(){
    return(
    <>
         <TabsContainer tabs={tabs}/>
    </>)
}