import axios from "axios";
import { useState } from "react";
const baseApiUrl=process.env.REACT_APP_API_URL;
const userSession=localStorage.getItem("User") || '{}';
const bearer= JSON.parse(userSession)?.message;
const headers={ headers: {"Authorization" : `Bearer ${bearer}`}}

type EndpointString = `/${string}`;
type EndPointStringWithParameter =`/${string}?${string}=`;
type deleteEndPointString =`/${string}?${string}=${string}`;


export async  function registerUser(data:any){
    return await axios.post(`${baseApiUrl}/Account/Register`,data,headers)
    .then((response: { data: any; })=>response.data)
    .catch((error: any)=> { 
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            alert(`${error?.response?.data?.errorMessage}`)
           // alert(`${error.response.data.message} : ${error.response.data.errors[0]}`)
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config); 
          
          throw error
    });
}
export async  function editUser(data:any){
  const editUserHeaders={ headers: {"Authorization" : `Bearer ${bearer}`, "Content-Type" : "application/multipart/form-data"}}

  return await axios.post(`${baseApiUrl}/Account/Update/UserInfo`,data,editUserHeaders)
  .then((response: { data: any; })=>response.data)
  .catch((error: any)=> { 
      if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          alert(`${error?.response?.data?.errorMessage}`)
         // alert(`${error.response.data.message} : ${error.response.data.errors[0]}`)
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config); 
        
        throw error
  });
}

export async  function patchData(data:any, objectId:any,endpoint:EndPointStringWithParameter){
  console.log(bearer)
    return await axios.patch(`${baseApiUrl}${endpoint}${objectId}`,data,{ headers: {"Authorization" : `Bearer ${bearer}`, "Content-Type" : "application/json-patch+json"}})
    .then((response: { data: any; })=>{
      alert("Success") 
      return response.data
    })
    .catch((error: any)=> { 
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log("Oops",localStorage.getItem("Bearer Token")?.toString());
            alert(`${error?.response?.data?.errorMessage}`)
            //alert(`${error?.response?.data?.message} : ${error?.response?.data?.errors[0]}`)
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config); 
          
          throw error
    });
}
export async  function postData(data:any,endpoint:EndpointString){
    return await axios.post(`${baseApiUrl}${endpoint}`,data,{headers: {"Authorization" : `Bearer ${bearer}`}})
    .then((response: { data: any; })=>{
      alert("Success")
      return response.data})
    .catch((error: any)=> { 
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            alert(`${error?.response?.data?.errorMessage}`)
            //alert(`${error?.response?.data?.message} : ${error?.response?.data?.errors[0]}`)
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config); 
          
          throw error
    });
}
export async  function deleteData(endpoint:deleteEndPointString){
    return await axios.delete(`${baseApiUrl}${endpoint}`,headers)
    .then((response: { data: any; })=>{
      alert ("Success")
      return response.data
    })
    .catch((error: any)=> { 
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            alert(`${error?.response?.data?.errorMessage}`)
           // alert(`${error.response.data.message} : ${error.response.data.errors[0]}`)
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
            console.log("Tis is",headers);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config); 
          
          throw error
    });
}

export async  function postImage(data:any,endpoint:EndpointString){

  let formData = new FormData();
  formData.append('file',data)
  return await axios.post(`${baseApiUrl}${endpoint}`,formData,{ headers: {"Authorization" : `Bearer ${bearer}`}}
  )
  .then((response: { data: any; })=>{
    alert ("Photo submited succesfully");
    return response.data;
  })
  .catch((error: any)=> { 
      if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          alert(`${error?.response?.data?.errorMessage}`)
          //alert(`${error.response.data.message} : ${error.response.data.errors[0]}`)
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config); 
        
        throw error
  });
}

export async function getData(dataEndpoint:EndpointString, contextBearer?:string){
 
    return await axios.get(`${baseApiUrl}${dataEndpoint}`,{headers: {"Authorization" : `Bearer ${bearer||contextBearer}`}})
    .then((response: { data: any; })=>response.data)
    .catch((error: any)=> { 
      if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          alert(`${error?.response?.data?.errorMessage}`)
          //alert(`${error?.response?.data?.message} : ${error?.response?.data?.errors[0]}`)
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config); 
        
        throw error
  });

}

export async function login(dataEndpoint:EndpointString){
  return await axios.get(`${baseApiUrl}${dataEndpoint}`,{headers: {'Authorization': 'Bearer ' + bearer}})
  .then((response: { data: any; })=>response.data)
  .catch((error: any)=> { 
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        //alert(`${error?.response?.data?.errorMessage}`)
        //alert(`${error?.response?.data?.message} : ${error?.response?.data?.errors[0]}`)
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config); 
      
      throw error
});

}
export async function getImage(dataEndpoint:EndpointString){
  return await axios.get(`${baseApiUrl}${dataEndpoint}`,{headers: {'Authorization': 'Bearer ' + bearer}, responseType:'blob'})
  .then((response: { data: any; })=>{ 
    console.log("Download", response.data)
    return response.data
  })
  .catch((error: any)=> { 
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error?.response?.data?.message);
        alert(`${error?.response?.data?.errorMessage}`)
//alert(`${error?.response?.data?.message} : ${error?.response?.data?.errors[0]}`)
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config); 
      
      throw error
});

}
export async  function getAllOwners(){
    return await axios.get(`${baseApiUrl}/Admin/GetAllUsers`,{ headers: {"Authorization" : `Bearer ${bearer}`}}).then((response: { data: any; })=>response.data).catch((error: any)=> {throw error});
}
export  async function getAllAddresses(){
    return await axios.get(`${baseApiUrl}/Address/GetAllAddresses`).then((response: { data: any; })=>response.data).catch((error: any)=> {throw error});
}

/*TODO: Add functionality to add owner only not register a user*/
export async function addOwner(data:any){
    return await axios.post(`${baseApiUrl}/Account/Register`,data,{headers: {'Authorization': 'Bearer ' + bearer}}).then((response: { data: any; })=>response.data).catch((error: any)=> {throw error});
}

export async function addAddress(data:any){
    return await axios.post(`${baseApiUrl}/Address`,data,{headers: {'Authorization': 'Bearer ' + bearer}}).then((response: { data: any; })=>response.data).catch((error: any)=> {throw error});
}

export async  function addEvent(data:any){
    return await axios.post(`${baseApiUrl}/Event`,data,{headers: {'Authorization': 'Bearer ' + bearer}}).then((response: { data: any; })=>response.data).catch((error: any)=> {throw error});
}


export  async function getAllVenues(){
    return await axios.get(`${baseApiUrl}/Venue/GetAllVenues`,{ headers: {"Authorization" : `Bearer ${bearer}`}}).then((response: { data: any; })=>response.data).catch((error: any)=> {throw error});
}
export async function addVenue(data:any){
    return await axios.post(`${baseApiUrl}/Address`,data,{headers: {'Authorization': 'Bearer ' + bearer}}).then((response: { data: any; })=>response.data).catch((error: any)=> {throw error});
}

export async function addRentPeriod(data:any){
    return await axios.post(`${baseApiUrl}/RentPeriod`,data,{headers: {'Authorization': 'Bearer ' + bearer}}).then((response: { data: any; })=>response.data).catch((error: any)=> {throw error});
}

export async function addParkingSpot(data:any){
    return await axios.post(`${baseApiUrl}/ParkingSpot`,data,{headers: {'Authorization': 'Bearer ' + bearer}}).then((response: { data: any; })=>response.data).catch((error: any)=> {throw error});
}



