

import { NavLink } from 'react-router-dom';

export default function SideBar(props: { logo: string | undefined; adminNavigation: any[]; classNames: (arg0: string, arg1: string) => string | undefined; otherNavigation: any[]; }){

    

    return(
    <>
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="border-r border-gray-200 pt-5 flex flex-col flex-grow bg-white overflow-y-auto">
            <div className="flex-shrink-0 px-4 flex items-center">
              <img
                className="h-8 w-auto"
                src={props.logo}
                alt="ParkShare"
              />
            </div>
            <div className="flex-grow mt-5 flex flex-col">
                <div className="flex-shrink-0 p-6 flex items-center">

                    <h3 className="text-lg font-bold sm:text-3xl  text-transparent bg-clip-text bg-gradient-to-r from-blue-700 via-pink-500 to-rose-600">
                        Hej, <br/> Admin.
                    </h3>

                </div>

                <nav className="flex-none px-2 pb-4 space-y-1">
                  {props.adminNavigation.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.to}
                      className={({isActive})=>{return (props.classNames(
                        isActive ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'group rounded-md py-2 px-2 flex items-center text-sm font-medium'
                      ))}}
                    >
                      <item.icon
                        className={props.classNames(
                          item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                          'mr-3 flex-shrink-0 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </NavLink>
                  ))}
              </nav>
              <span className=' h-1 bg-gradient-to-r from-blue-700 via-pink-500 to-rose-600 mt-4 mx-12' ></span>

              <div className="flex-shrink-0 p-6 flex items-center">

                    <h3 className="text-base font-bold sm:text-xl  text-transparent bg-clip-text bg-gradient-to-r from-blue-700 via-blue-700 to-blue-700">
                       Other
                    </h3>

                </div>
                
              <nav className="flex-none px-2 pb-4 space-y-1">
                {props.otherNavigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.to}
                    className={({isActive})=>{return (props.classNames(
                      isActive ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                      'group rounded-md py-2 px-2 flex items-center text-sm font-medium'
                    ))}}
                  >
                    <item.icon
                      className={props.classNames(
                        item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </NavLink>
                ))}
              </nav>

              <span className=' h-1 bg-gradient-to-r from-blue-700 via-pink-500 to-rose-600 mt-4 mx-12 mb-4' ></span>
            </div>
          </div>
        </div>

    </>)
}