import React, { Fragment } from 'react'
import * as Yup from 'yup';

import { Dialog, Transition } from '@headlessui/react'
import { PlusCircleIcon, TicketIcon } from '@heroicons/react/outline'
import useFetchData from '../../../hooks/useFetchData'
import {getAllVenues,  getAllAddresses, addVenue, addEvent, getData, addRentPeriod, postData } from '../../../api'
import { Field, Form, Formik } from 'formik'
import usePostData from '../../../hooks/usePostData'
import { useMutation, useQueryClient } from 'react-query';
import SmallSpinner from '../../../components/SmallSpinner';


export default function AssignEventRentPeriodModal(props: any) {

    const {event}=props;
    const {isLoading, isError, data,  error} = useFetchData('allParkingSpots',getData('/ParkingSpot/GetAllParkingSpots'));
    data?.sort((a:ParkingSpot,b:ParkingSpot)=>(a.address.streetName.localeCompare(b.address.streetName) || Number(a.address.streetNumber) -Number(b.address.streetNumber)));
    

  interface RentPeriod{
    rentPeriodStart:string
    rentPeriodEnd:string,
    eventId:Number,
    rentPeriodType:Number,
    parkingSpotId:Number,
    monthlyRentPeriodType: 0,
    moFiStartTime: TimeRanges|null,
    moFiEndTime: TimeRanges|null,
    satStart: TimeRanges|null,
    satEnd: TimeRanges|null,
    sunStart: TimeRanges|null,
    sunEnd: TimeRanges|null
  }

  interface  ZipCodeDistrict{
    zipcode: number;
    city: string;
  }
  
  interface  ParkingAddress{
    streetName: string;
    streetNumber: string;
    zipCodeDistrict: ZipCodeDistrict;
  }
  
  interface  ParkingSpot{
    parkingSpotId: number,
    address: ParkingAddress;
    description:string;
    isRentedOut:boolean;
    
  }

  

  const initialValues:RentPeriod = {
    rentPeriodStart:event?.startTime,
    rentPeriodEnd:event?.endTime,
    eventId:event?.eventId,
    rentPeriodType:2,
    parkingSpotId:0,
    monthlyRentPeriodType: 0,
    moFiStartTime: null,
    moFiEndTime: null,
    satStart: null,
    satEnd: null,
    sunStart: null,
    sunEnd: null
  };
    
  const validationSchema = Yup.object({
     parkingSpotId: Yup.number().required('Required'), 
    })

  const queryClient=useQueryClient()

  const mutation = useMutation(newRentPeriod=>postData(newRentPeriod,'/RentPeriod'),{
      onSuccess:()=>{
        queryClient.invalidateQueries("events")
        props.setOpenClose()
      }
  });

  const handleSubmit=(data:any)=>{
    mutation.mutate(data)
  }


  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={()=>props.setOpenClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 md:max-w-2xl sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <PlusCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-xl leading-6 font-medium text-gray-900">
                      Assign Parking Spot to Event
                    </Dialog.Title>
                
                    <div className="mt-2">
                        <div className="space-y-8 divide-y divide-gray-200">
                            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                <div>
                                    <div className="my-6  rounded border-2  border-pink-500 grid grid-cols-1 ">
                                        <div className="col-span-1 p-4 ">
                                            <span> <TicketIcon className="h-8 text-blue-700"/></span>
                                            <h1 className="text-2xl font-semibold text-start"> {event?.name}</h1>
                                            <h1 className="text-start"> {event?.venue?.name}</h1>
                                        </div>

                                  
                                        
                                    </div>
                                    <div>
                                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                        Select the parking spot to assign to this event
                                        </p>
                                    </div>

                                    <Formik 
                                      initialValues={initialValues} 
                                      onSubmit={(values,{setSubmitting}) =>
                                          {
                                            console.log("Attempting to add new rental period for event", values);
                                                    
                                                   handleSubmit(values)

                                                   mutation.isError? setSubmitting(false):
                                                   mutation.isError? alert('An error occured check log for details'):
                                                   mutation.isLoading? setSubmitting(true):
                                                   mutation.isSuccess? setSubmitting(false): setSubmitting(true);
                                          } 
                                      }
                                      validationSchema={validationSchema}
                                      
                                  >

                                    {({isSubmitting,submitForm,handleChange,handleBlur,errors,touched,values,setSubmitting}) =>(
                                          <Form onSubmit={ (e)=>{
                                              e.preventDefault();
                                              console.log("Attempting to submit new event rent period", values);
                                              
                                            
                                              handleSubmit(values)

                                              mutation.isError? setSubmitting(false):
                                              mutation.isError? alert('An error occured check log for details'):
                                              mutation.isLoading? setSubmitting(true):
                                              mutation.isSuccess? setSubmitting(false): setSubmitting(true);
                                              
                                              
                                              } }>
                                              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                  <div className="sm:col-span-6 grid justify-items-start">
                                                  <>
                                                  {isLoading? <SmallSpinner/>:isError?<>{error}</>:
                                                    <Field
                                                    as="select"
                                                    title='event-parking'
                                                    id="event-parking"
                                                    name="parkingSpotId"
                                                    type="number"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                    >
                                                        <option key={'empty-parking'}>Select parking spot to assign to {event?.name} event</option>
                                                        
                                                        {data?.map((parkingSpot:ParkingSpot) => (
                                                            <option key={parkingSpot?.parkingSpotId} value={parkingSpot?.parkingSpotId}>{ `${parkingSpot?.address?.streetName} ${parkingSpot?.address?.streetNumber} ${" at "} ${parkingSpot?.address?.zipCodeDistrict?.zipcode} ${parkingSpot?.address?.zipCodeDistrict?.city} ${(parkingSpot?.description).substring(0,50)} ${'...'}` }</option>
                                                        ))}
                                                    </Field>
                                                  }
                                                      

                                                      {errors.parkingSpotId && touched.parkingSpotId && <div>{"Select a parking spot"}</div>}

                                                      </>
                                                  </div>

                                                  
                                              </div>
                                              <div className="flex justify-end mt-4">
                                              
                                                  <button
                                                      type="submit"
                                                      disabled={isSubmitting}
                                                      onClick={submitForm}
                                                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                  >
                                                      Save
                                                  </button>
                                              </div>

                                          </Form>
                                      )}
                        
                                    </Formik>
                                        

                                  
                                </div>
                            </div>

                            

                           
                        </div>
                    </div>
                  </div>
                </div>
               
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}


