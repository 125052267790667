import { MailIcon } from '@heroicons/react/solid';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState } from 'react';
import { getData } from "../../../api";
import TableRowActionButton from '../../../components/ParkingsTable/TableRowAction';
import SmallSpinner from '../../../components/SmallSpinner';
import TableContainer from '../../../components/TableContainer';
import useFetchData from "../../../hooks/useFetchData";
import AddAddressModal from '../../AddressManagement/AddAddressModal';
import AddOwnerModal from './AddOwnerModal';



interface Address{
    addressId:number;
    streetName: string;
    streetNumber: string;
    zipCode: number;
    area: string;
  }

interface Owner{
    id: string,
    accountNo:string,
    regNo:string,
    user:User
    addresses:Array<Address>
   
}


type User={
    email:string;
    firstName:string;
    lastName:string;
    phoneNumber:string;
    userId:string;
    active:boolean;
    address:string;
    zipcode:number;
    emailConfirmed:boolean;
}


export default function AllOwners() {

  const [isOpenAddOwnerModal,setIsOpenAddOwnerModal]=useState(false)
  const [isOpenAddAddressModal,setOpenAddAddressModal]=useState(false);
  const [selectedOwner, setSelectedOwner]=useState({})

  const {isLoading, isError, data,  error} = useFetchData('allOwners',getData('/Owner/GetAllOwners'));
  // const refreshTable = React.useReducer(() => ({}), {})[1]
  const reserveSpot = ()=>{return false}

  const toggleAddOwner=()=>{
    setIsOpenAddOwnerModal(!isOpenAddOwnerModal)
  }

  const toggleAddAddressModal=()=>{
    setOpenAddAddressModal(!isOpenAddAddressModal)
  };


  const columnHelper = createColumnHelper<Owner>()
  const columns= [
    columnHelper.accessor(row => `${row.user.firstName} ${row.user.lastName}`, {
        id: 'name',
        cell: data => 
            <span className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                {data.getValue()}
            </span>,
        header: () => <span> Name </span>,
        footer: data => data.column.id,
      }),
    columnHelper.accessor(row => `${row.user.phoneNumber}`, {
      id: 'phoneNumber',
      cell: data => 
          <span className="whitespace-nowrap px-3 py-4 text-sm  text-gray-500 hidden sm:table-cell ">
            {data.getValue()}
          </span>,
      header: () => <span>Phone Number</span>,
      footer: data => data.column.id,
    }),
    columnHelper.accessor(row => `${row.user.email}`, {
      id: 'email',
      header: () => <span>Email</span>,
      cell: data =>
            <span className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell ">
              {data.getValue()}
            </span>,
      footer: data => data.column.id,
      
    }),
    columnHelper.accessor('accountNo', {
        id: 'accountNo',
        header: () => <span>Account No</span>,
        cell: data =>
              <span className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell ">
                {data.getValue()}
              </span>,
        footer: data => data.column.id,
        
    }),
    columnHelper.accessor('regNo', {
        id: 'regNo',
        header: () => <span>Reg No</span>,
        cell: data =>
              <span className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell ">
                {data.getValue()}
              </span>,
        footer: data => data.column.id,
        
      }),

      columnHelper.accessor(row => `${row.addresses.length}`, {
        id: 'addresses',
        header: () => <span>Addresses</span>,
        cell: data =>
              <span className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell ">
                {data.getValue()}
              </span>,
        footer: data => data.column.id,
        
      }),
  
    columnHelper.accessor(row => `${row.user.active}`, {
        id: 'status',
        header: ()=><span> Status</span>,
        cell: data => 
              <span className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"> 
                {data.getValue()?
  
              <>
                  <div className="flex flex-row">
                      <div className="flex items-center"> 
                          <span className="flex h-3 w-3">
                          <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-green-400 opacity-75"></span>
                          <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                          </span>
  
                      </div>
  
                      <div>
                          <span className="whitespace-nowrap px-3 text-sm text-gray-500 hidden sm:table-cell ">
                          Active
                          </span>
                      </div>
                  </div>
  
              </>
                : 
                <>
                  <div className="flex flex-row">
                    <div className="flex items-center"> 
                      <span className="flex h-3 w-3">
                        <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-amber-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-amber-500"></span>
                      </span>
  
                    </div>
                  
                    <div>
                      <span className="whitespace-nowrap px-3 text-sm text-gray-500 hidden sm:table-cell ">
                        Pending
                      </span>
                    </div>
                  </div>
                  
                </>
                
                }
              </span>, 
        footer: data => data.column.id,
      }),
    
    columnHelper.display({
      header:()=><span> Action</span>,
      id: 'action',
      cell: (data: { row: any; }) =>
        <>
            <div className="flex flex-col items-center">
                <button
                    
                    type="button"
                    className="inline-flex items-center px-2 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={()=>{
                        setSelectedOwner(data.row.original.ownerId)
                        toggleAddAddressModal()
                    }}
                >
                    Add Address
                </button>

            </div>
           
        </>
    }),
    
  ]
  
  if(isError){

      console.log(error);
      return(
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto">{"Error fetching data"}</div>
          </div>
        </>
      )
    }
  return(
      <>
        <div className="pt-6">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">All Owners</h1>
              <p className="mt-2 text-sm text-gray-700">These are all owners registered with ParkShare</p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  onClick={toggleAddOwner}
              >
                 Add Owner
              </button>
            </div>
          </div>
        </div>

        {
          isLoading?
          <>
            Loading Data <SmallSpinner/>
          </>
          :
          <>
             <TableContainer
              title={"All Owners"} 
              description={"These are all owners registered with ParkShare"} 
              data={data}
              isLoading={isLoading}
              noDataMessage="User data not found. Add new owners"
              columns={columns}
              />
          </>
        }
       

        {
          isOpenAddOwnerModal &&
          <AddOwnerModal isOpen={isOpenAddOwnerModal} setOpenClose={toggleAddOwner}/>
        }

        { isOpenAddAddressModal && 
            <AddAddressModal isOpen={isOpenAddAddressModal} setOpenClose={toggleAddAddressModal} selectedOwner={selectedOwner}/>
        }

            
      </>
  )
    
}