import { Formik, Field, Form } from "formik";
import { useQueryClient, useMutation } from "react-query";
import { addParkingSpot } from "../../../api";
import * as Yup from 'yup';



interface ParkingSpot{
    addressId:number,
    description: string,
    spotSize: number,
    hourly:number,
    monthly:number
  }

  interface Owner {
    id:number
    firstName: string;
    lastName: string;
    email: string;
    address: string;
    phoneNumber: string;
    zipCode: number;
    password:string;
    confirmPassword: string;
}


export default function AddParkingSpotForm(props:any){

    const {selectedAddress,toggleModal} =props;
    console.log(selectedAddress)

    const queryClient=useQueryClient()

    const mutation = useMutation(newParkingSpot=>addParkingSpot(newParkingSpot),{
        onSuccess:()=>{
          queryClient.invalidateQueries("allParkingSpots")
          props.toggleModal()
        },
        onError:(error)=>{
            alert(error)

        }
    });
  
    const handleSubmit=(data:any)=>{
      mutation.mutate(data)
    }
  
    const initialValues:ParkingSpot = {addressId: selectedAddress?.address?.addressId, description:"",spotSize:0,hourly:1,  monthly:0};
    const parkingSpotValidationSchema = Yup.object().shape({
        addressId: Yup.number().required('Required'),
        description: Yup.string()
          .min(50, 'Too Short!')
          .max(500, 'Too Long!')
          .required('Required'),
        spotSize: Yup.number().required('Required'),
        hourly: Yup.number().required('Required'),
        monthly: Yup.number().required('Required'),
      });
  
  
    return(
        <>
            <div className={'pt-8'}>
                <div className="grid justify-items-start border  p-4 border-gray-500 rounded">
                    <h4 className="text-2xl leading-6 font-medium text-gray-900">{`${selectedAddress?.address?.streetName} ${selectedAddress?.address?.streetNumber}`}</h4>
                    <h4 className="text-xl leading-6 font-medium text-gray-700">{`${selectedAddress?.address?.zipCodeDistrict?.city} ${selectedAddress?.address?.zipCodeDistrict?.zipcode}`}</h4>

                    <p className="mt-4 text-sm text-gray-500 text-left">You are creating adding a new parking spot for this address, <br></br>enter the details below.</p>
                </div>


                <Formik 
                    initialValues={initialValues} 
                    validationSchema={parkingSpotValidationSchema}
                    onSubmit={(values,{setSubmitting})=>
                        {
                        console.log("Attempting to submit new parking spot", values);
                                    
                        handleSubmit(values)

                        mutation.isError? setSubmitting(false):
                        mutation.isError? alert('An error occured check log for details'):
                        mutation.isLoading? setSubmitting(true):
                        mutation.isSuccess? setSubmitting(false): setSubmitting(true);

                            

                        }}
                >
                    {({isSubmitting,submitForm,handleChange,handleBlur,errors,touched,values,setSubmitting}) =>
                    (
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        
                        }
                    }
                    >

                        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="owner" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Owner
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="sm:col-span-3">
                                        <div className="mt-1">

                                        <Field
                                            as="select"
                                            id="owner"
                                            name="owner"
                                            autoComplete="owner-name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        >
                                            <option key={7} value={7}>Kelvin 7</option>
                                            {data?.map((owner:Owner) => (
                                                <option key={owner.id}>{owner.firstName + ' ' + owner.lastName}</option>
                                                ))}
                                        </Field>
                                                                                                    
                                            <DisclosureA
                                                title={"Add Owner"}
                                                content={<AddOwnerForm/>}
                                            />

                                            
                                        </div>
                                        
                                    </div>
                                </div>
                            </div> */}
           
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="spot-size" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Spot Size
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="sm:col-span-3">
                                        <div className="mt-1">
                                            <Field
                                                as="select"
                                                title="spot-size"
                                                id="spot-size"
                                                name="spotSize"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                >
                                                <option key={"small"} value={0}>Small</option>
                                                <option key={"normal"} value={1}>Normal</option>
                                            </Field>
                                            {errors.spotSize && touched.spotSize && <div>{errors.spotSize}</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                                    <label htmlFor="hourly" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                        Hourly
                                                    </label>
                                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                        <div className="sm:col-span-3">
                                                            <div className="mt-1">
                                                                <Field
                                                                  as="select"
                                                                  title="hourly"
                                                                  id="hourly"
                                                                  name="hourly"
                                                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                                  >
                                                                  <option key={"default"}>Select Yes or No</option>
                                                                  <option key={"yes"} value={1}>Yes</option>
                                                                  <option key={"no"} value={2}>No</option>
                                                                </Field>
                                                                {errors.hourly && touched.hourly && <div>{errors.hourly}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                                    <label htmlFor="monthly" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                        Monthly
                                                    </label>
                                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                        <div className="sm:col-span-3">
                                                            <div className="mt-1">
                                                                <Field
                                                                  as="select"
                                                                  title="monthly"
                                                                  id="monthly"
                                                                  name="monthly"
                                                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                                  >
                                                                  <option key={"default"}>Select Yes or No</option>
                                                                  <option key={"yes"} value={1}>Yes</option>
                                                                  <option key={"no"} value={2}>No</option>
                                                                </Field>
                                                                {errors.hourly && touched.hourly && <div>{errors.hourly}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="spot-description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Description
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <Field
                                    as="textarea"
                                    id="spot-description"
                                    name="description"
                                    rows={3}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                                    placeholder="Write a few sentences describing the parking spot e.g where it is and how to access it"
                                    />
                                    {errors.description && touched.description && <div>{errors.description}</div>}
                                </div>
                            </div>

                        
                                <div className="pt-5">
                                    <div className="flex justify-end">
                                    <button
                                        type="button"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={toggleModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                    >
                                        Add Spot
                                    </button>
                                    </div>
                                </div>

                        </div>
                    </Form>
                    )}
                </Formik>
            </div>
           
        </>
    )
}