interface PulseIndicatorConfig {
    color: string,
    height: string,
    width: string
}
export default function PulseIndicator(props:PulseIndicatorConfig){
    return(
        <span className="flex h-3 w-3">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
            <span className={`relative inline-flex rounded-full h-3 w-3 ${props.color} `}></span>
        </span>
    )
}