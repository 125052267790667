import { MailIcon } from '@heroicons/react/solid';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState } from 'react';
import { getData } from "../../../api";
import SmallSpinner from '../../../components/SmallSpinner';
import TableContainer from '../../../components/TableContainer';
import useFetchData from "../../../hooks/useFetchData";
import RegisterUserModal from '../RegisterUserModal';
import { useAuth } from '../../../hooks/useAuth';
import EditUserModal from '../EditUserModal';





type User={
    email?:string;
    firstName:string;
    lastName:string;
    phoneNumber:string;
    userId:string;
    active:boolean;
    address:string;
    zipcode:number;
    emailConfirmed:boolean;
}

export default function AllUsers() {
  const { user } = useAuth();
  const[selectedUser,setSelectedUser]=useState({})

  const [isOpenRegisterUserModal,setIsOpenRegisterUserModal]=useState(false)
  const [isOpenEditUserModal,setIsOpenEditUserModal]=useState(false)
  const {isLoading, isError, data,  error} = useFetchData('users',getData('/Admin/GetAllUsers', user?.message));
  // const refreshTable = React.useReducer(() => ({}), {})[1]
  const reserveSpot = ()=>{return false}

  const toggleRegisterUser=()=>{
    setIsOpenRegisterUserModal(!isOpenRegisterUserModal)
  }
  const toggleEditUser=()=>{
    setIsOpenEditUserModal(!isOpenEditUserModal)
  }


  const columnHelper = createColumnHelper<User>()
  const columns= [
    columnHelper.accessor(row => `${row.firstName} ${row.lastName}`, {
        id: 'name',
        cell: data => 
            <span className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                {data.getValue()}
            </span>,
        header: () => <span> Name </span>,
        footer: data => data.column.id,
      }),
    columnHelper.accessor('phoneNumber', {
      id: 'phonrNumber',
      cell: data => 
          <span className="whitespace-nowrap px-3 py-4 text-sm  text-gray-500 hidden sm:table-cell ">
            {data.getValue()}
          </span>,
      header: () => <span>Phone Number</span>,
      footer: data => data.column.id,
    }),
    columnHelper.accessor('email', {
      header: () => <span>Email</span>,
      cell: data =>
            <span className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell ">
              {data.getValue()}
            </span>,
      footer: data => data.column.id,
      
    }),
    columnHelper.accessor('emailConfirmed', {
      header: ()=><span> Email Confirmed</span>,
      cell: data => 
            <span className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"> 
              {data.getValue()?

            <>
                <div className="flex flex-row">
                    <div className="flex items-center"> 
                       <MailIcon className='h-4'/>
                    </div>

                    <div>
                        <span className="whitespace-nowrap px-3 text-sm text-gray-500 hidden sm:table-cell ">
                        Confirmed
                        </span>
                    </div>
                </div>

            </>
              : 
              <>
                <div className="flex flex-row">
                  <div className="flex items-center"> 
                    <MailIcon className="h-4"/>

                  </div>
                
                  <div>
                    <span className="whitespace-nowrap px-3 text-sm text-gray-500 hidden sm:table-cell ">
                      Pending
                    </span>
                  </div>
                </div>
                
              </>
              
              }
            </span>, 
      footer: data => data.column.id,
    }),
    columnHelper.accessor('active', {
        header: ()=><span> Status</span>,
        cell: data => 
              <span className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"> 
                {data.getValue()?
  
              <>
                  <div className="flex flex-row">
                      <div className="flex items-center"> 
                          <span className="flex h-3 w-3">
                          <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-green-400 opacity-75"></span>
                          <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                          </span>
  
                      </div>
  
                      <div>
                          <span className="whitespace-nowrap px-3 text-sm text-gray-500 hidden sm:table-cell ">
                          Active
                          </span>
                      </div>
                  </div>
  
              </>
                : 
                <>
                  <div className="flex flex-row">
                    <div className="flex items-center"> 
                      <span className="flex h-3 w-3">
                        <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-amber-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-amber-500"></span>
                      </span>
  
                    </div>
                  
                    <div>
                      <span className="whitespace-nowrap px-3 text-sm text-gray-500 hidden sm:table-cell ">
                        Pending
                      </span>
                    </div>
                  </div>
                  
                </>
                
                }
              </span>, 
        footer: data => data.column.id,
      }),
    
    columnHelper.display({
      header:()=><span> Contact</span>,
      id: 'mail',
      cell: (data: { row: any; }) =>
        <>
            <div className="flex flex-col items-center">
                <a
                    href={`mailto:${data.row.original.email}`}
                    type="button"
                    className="inline-flex items-center px-2 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Mail
                </a>

            </div>
           
        </>
    }),
    columnHelper.display({
      header:()=><span> Edit</span>,
      id: 'edit',
      cell: (data: { row: any; }) =>
        <>
            <div className="flex flex-col items-center">
                <button
                    type="button"
                    className="inline-flex items-center px-2 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={()=>{
                      setSelectedUser(data.row.original)
                      toggleEditUser()
                    }}
                >
                    Edit
                </button>

            </div>
           
        </>
    }),
    
  ]
  
  if(isError){

      console.log(error);
      return(
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto">{"Error fetching data"}</div>
          </div>
        </>
      )
    }
  return(
      <>
        <div className="pt-6">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">All Users</h1>
              <p className="mt-2 text-sm text-gray-700">These are all users, owners and renters registered with ParkShare</p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  onClick={toggleRegisterUser}
              >
                 Register User
              </button>
            </div>
          </div>
        </div>

        {
          isLoading?
          <>
            Loading Data <SmallSpinner/>
          </>
          :
          <>
             <TableContainer
              title={"All Users"} 
              description={"These are all user, owners and renters registered with ParkShare"} 
              data={data}
              isLoading={isLoading}
              noDataMessage="User data not found. Register new users"
              columns={columns}
              />
          </>
        }
       

        {
          isOpenRegisterUserModal &&
          <RegisterUserModal isOpen={isOpenRegisterUserModal} setOpenClose={toggleRegisterUser}/>
        }
        {
          isOpenEditUserModal &&
          <EditUserModal isOpen={isOpenEditUserModal} setOpenClose={toggleEditUser} user= {selectedUser}/>
        }

            
      </>
  )
    
}