import { createColumnHelper } from '@tanstack/react-table';
import React, { useState } from 'react';
import { getData } from "../../../api";
import TableRowActionButton from '../../../components/ParkingsTable/TableRowAction';
import PulseIndicator from '../../../components/PulseIndicator';
import SmallSpinner from '../../../components/SmallSpinner';
import TableContainer from '../../../components/TableContainer';
import useFetchData from "../../../hooks/useFetchData";
import AddParkingModal from '../AddParkingModal';
import AddPhotoModal from '../AddPhotoModal';
import EditParkingModal from '../EditParkingModal';



type ZipCodeDistrict={
  zipcode: number;
  city: string;
}

type ParkingsTableAddress={
  streetName: string;
  streetNumber: string;
  zipCodeDistrict: ZipCodeDistrict;
}

type ParkingSpot={
  parkingSpotId: number,
  address: ParkingsTableAddress;
  description:string;
  isRentedOut:boolean;
  
}

export default function AllParkings() {

  const [isOpenAddParkingModal,setIsOpenAddParkingModal]=useState(false)
  const [isOpenEditParkingModal,setOpenEditParkingModal]=useState(false)
  const [isOpenAddPhotoModal,setOpenAddPhotoModal]=useState(false)

  const [selectedParking,setSelectedParking]=useState({})
  const {isLoading, isError, data,  error} = useFetchData('allParkingSpots',getData('/ParkingSpot/GetAllParkingSpots'));
  // const refreshTable = React.useReducer(() => ({}), {})[1]
  const reserveSpot = ()=>{return false}

  const toggleAddParkingModal=()=>{
    setIsOpenAddParkingModal(!isOpenAddParkingModal)
  }
  const toggleEditParkingModal=()=>{
    setOpenEditParkingModal(!isOpenEditParkingModal)
  }
  const toggleAddPhotoModal=()=>{
    setOpenAddPhotoModal(!isOpenAddPhotoModal)
  }



  const columnHelper = createColumnHelper<ParkingSpot>()
  const columns= [
    columnHelper.accessor(row => `${row.address?.streetName} ${row.address?.streetNumber}`, {
        id: 'address',
        cell: data => 
            <span className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                {data.getValue()}
            </span>,
        header: () => <span>Address</span>,
        footer: data => data.column.id,
      }),
    columnHelper.accessor(row => `${row.address?.zipCodeDistrict?.city} ${row.address?.zipCodeDistrict?.zipcode}`, {
      id: 'area',
      cell: data => 
          <span className="whitespace-nowrap px-3 py-4 text-sm  text-gray-500 hidden sm:table-cell ">
            {data.getValue()}
          </span>,
      header: () => <span>Area</span>,
      footer: data => data.column.id,
    }),
    columnHelper.accessor('description', {
      header: () => <span>Description</span>,
      maxSize: 100,
      
      cell: data =>
            <span className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell ">
              {data.getValue()}
            </span>,
      footer: data => data.column.id,
      
    }),
    columnHelper.accessor('isRentedOut', {
      header: ()=><span> Rented</span>,
      maxSize: 50,
      cell: data => 
            <span className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"> 
              {data.getValue()?
              <>
                <>
                <div className="flex flex-row">
                  <div className="flex items-center"> 
                    <span className="flex h-3 w-3">
                      <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-green-400 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                    </span>

                  </div>
                
                  <div>
                    <span className="whitespace-nowrap px-3 text-sm text-gray-500 hidden sm:table-cell ">
                      Rented
                    </span>
                  </div>
                </div>
                
              </>
              </> 
              : 
              <>
                <div className="flex flex-row">
                  <div className="flex items-center"> 
                    <span className="flex h-3 w-3">
                      <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-amber-400 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-3 w-3 bg-amber-500"></span>
                    </span>

                  </div>
                
                  <div>
                    <span className="whitespace-nowrap px-3 text-sm text-gray-500 hidden sm:table-cell ">
                      Not Rented
                    </span>
                  </div>
                </div>
                
              </>
              
              }
            </span>, 
      footer: data => data.column.id,
    }),
    

    columnHelper.display({
      header:()=><span> Action</span>,
      id: 'edit',
      cell: (data: { row: any; }) => (
        <>
            <div className="flex flex-col flex-nowrap items-center">
    
                  <button
                        
                        type="button"
                        className="inline-flex items-center px-1 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-indigo-500 bg-white hover:text-indigo-700"
                        onClick={()=>{
                          setSelectedParking(data.row.original)
                          toggleEditParkingModal()
                          
                        }}
                  >
                        Edit
                  </button>
            </div>
           
        </>
      )
    }),
    columnHelper.display({
      header:()=><span> Action</span>,
      id: 'addPhoto',
      cell: (data: { row: any; }) => (
        <>
            <div className="flex flex-col flex-nowrap items-center">
    
                  <button
                        
                        type="button"
                        className="inline-flex items-center px-1 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-indigo-500 bg-white hover:text-indigo-700"
                        onClick={()=>{
                          setSelectedParking(data.row.original)
                          toggleAddPhotoModal()
                          
                        }}
                  >
                        Add Photo
                  </button>
            </div>
           
        </>
      )
    }),
    
    columnHelper.display({
      header:()=><span> Action</span>,
      id: 'action',
      cell: (data: { row: any; }) => <TableRowActionButton title={"Rent Out"} action={reserveSpot} row={data.row}/>
    }),
    
    
  ]
  
  if(isError){

      console.log(error);
      return(
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto">{"Error fetching data"}</div>
          </div>
        </>
      )
    }
  return(
      <>
        <div className="pt-6">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">All Parkings</h1>
              <p className="mt-2 text-sm text-gray-700">These are all parkings registered with ParkShare</p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  onClick={toggleAddParkingModal}
              >
                 Add Parking Spot
              </button>
            </div>
          </div>
        </div>

        {
          isLoading?
          <>
            Loading Data <SmallSpinner/>
          </>
          :
          <>
             <TableContainer
              title={"All Parkings"} 
              description={"These are all parkings registered with ParkShare"} 
              data={data}
              isLoading={isLoading}
              noDataMessage="Parking data not found click on add parking to add parking spots"
              columns={columns}
              />
          </>
        }
       

        {
          isOpenAddParkingModal &&
          <AddParkingModal isOpen={isOpenAddParkingModal} setOpenClose={toggleAddParkingModal}/>
        }
        {
          isOpenEditParkingModal &&
          <EditParkingModal isOpen={isOpenEditParkingModal} setOpenClose={toggleEditParkingModal} parking={selectedParking}/>
        }
         {
          isOpenAddPhotoModal &&
          <AddPhotoModal isOpen={isOpenAddPhotoModal} setOpenClose={toggleAddPhotoModal} parking={selectedParking}/>
        }

            
      </>
  )
    
}