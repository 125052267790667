import {useState} from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from 'react-router-dom';
import PropTypes from 'prop-types';


import SideBars from './SideBars';
import TopBar from './TopBar';
import Dashboard from '../pages/Dashboard';
import ParkingManagement from '../pages/ParkingManagement';
import ActiveParkings from '../pages/ParkingManagement/ParkingManagementTabs/ActiveParkings';
import AllParkings from '../pages/ParkingManagement/ParkingManagementTabs/AllParkings';
import FreeParkings from '../pages/ParkingManagement/ParkingManagementTabs/FreeParkings';
import UserProfile from '../pages/AdminProfile';
import Login from '../pages/Login';
import EventManagement from '../pages/EventsManagement';
import AllEvents from '../pages/EventsManagement/EventManagementTabs/AllEvents';
import ActiveEvents from '../pages/EventsManagement/EventManagementTabs/ActiveEvents';
import UpcomingEvents from '../pages/EventsManagement/EventManagementTabs/UpcomingEvents';
import OwnerManagement from '../pages/OwnerManagement';
import AllOwners from '../pages/OwnerManagement/OwnerManagementTabs/AllOwners';
import ActiveOwners from '../pages/OwnerManagement/OwnerManagementTabs/ActiveOwners';
import AddressManagement from '../pages/AddressManagement';
import AllAddresses from '../pages/AddressManagement/AddressManagementTabs/AllAddresses';
import AllUsers from '../pages/UserManagement/UserManagementTabs/AllUsers';
import UserManagement from '../pages/UserManagement';
import AdminProfile from '../pages/AdminProfile';
import EventParkingSpots from '../pages/EventsManagement/EventParkingSpots';
import DriverManagement from '../pages/DriverManagement';
import ActiveDrivers from '../pages/DriverManagement/DriverManagementTabs/ActiveDrivers';
import AllDrivers from '../pages/DriverManagement/DriverManagementTabs/AllDrivers';
import { AuthProvider } from '../hooks/useAuth';

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
  }

const Routing = () => {
    const [sidebarOpen, setSidebarOpen]=useState(false)

    return (
        <Router>
            <AuthProvider>
                <SideBars sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} classNames={classNames}/>
                
                <main className="flex-1">
                <div className="md:pl-64">
                        <div className="max-w-full mx-auto flex flex-col md:px-4">
                            <TopBar setSidebarOpen={setSidebarOpen} classNames={classNames}/>
                            <Routes>
                                <Route path="/" element={<Dashboard/>} />
                                <Route path="/dashboard" element={<Dashboard/>} />
                                <Route path="/parking-management" element={<ParkingManagement/>} >
                                    <Route path="/parking-management/all-parkings" element={<AllParkings/>} />
                                    <Route path="/parking-management/active-parkings" element={<ActiveParkings/>} />
                                    <Route path="/parking-management/free-parkings" element={<FreeParkings/>} />
                                </Route>
                                <Route path="event-management" element={<EventManagement/>}>
                                    <Route path="all-events" element={<AllEvents/>} />
                                    <Route path="upcoming-events" element={<UpcomingEvents/>} />
                                    <Route path="active-events" element={<ActiveEvents/>} />
                                </Route>

                                <Route path="driver-management" element={<DriverManagement/>}>
                                    <Route path="all-drivers" element={<AllDrivers/>}/>
                                    <Route path="active-drivers" element={<ActiveDrivers/>}/>
                                </Route>
                                
                                <Route path="owner-management" element={<OwnerManagement/>}>
                                    <Route path="all-owners" element={<AllOwners/>}/>
                                    <Route path="active-owners" element={<ActiveOwners/>}/>
                                </Route>

                                <Route path="address-management" element={<AddressManagement/>}>
                                    <Route path="all-addresses" element={<AllAddresses/>}/>
                                </Route>

                                <Route path="user-management" element={<UserManagement/>}>
                                    <Route path="all-users" element={<AllUsers/>}/>
                                </Route>

                                {/* <Route path="event-parkings" element={<EventParkingSpots/>}>
                                    <Route path=":id" element={<EventParkingSpots />} />
                                </Route> */}
                                <Route path="/event-parkings/:id/:eventName/:eventVenue/:price/:category" element={<EventParkingSpots/>} />
                                <Route path="/admin-profile" element={<AdminProfile/>} />
                                <Route path="/login" element={<Login/>} />
                            
                
                            </Routes>             
                        </div>
                    </div>
                </main>
            </AuthProvider>
                
           
        </Router>
    );
}


Routing.propTypes = {
    props: PropTypes.any,
};

export default Routing