import React, { Fragment, useState } from 'react'
import * as Yup from 'yup';

import { Dialog, Transition } from '@headlessui/react'
import { PlusCircleIcon } from '@heroicons/react/outline'
import DisclosureA from '../../../components/Disclosure'
import AddAddressForm from './AddVenueForm'
import useFetchData from '../../../hooks/useFetchData'
import {getAllVenues,  getAllAddresses, addVenue, addEvent } from '../../../api'
import AddVenueForm from './AddVenueForm'
import { Field, Form, Formik } from 'formik'
import usePostData from '../../../hooks/usePostData'
import { useMutation, useQueryClient } from 'react-query';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DatepickerField from '../../../components/DatePickerField';




export default function AddEventModal(props: any) {

  const eventCategories=[
    { name: "Football",
      id: 0
    },
    { name: "Concert",
      id: 1
    },
    { name: "Other",
      id: 2
    }
  ]

  const spots: Array<number> = [];
  const populateSpots = ()=> {
    for(let i=0; i<100; i++){
      spots.push(i)
    }
    
  }

  populateSpots();
  const {isLoading, isError, data,  error} = useFetchData('venues',getAllVenues());
  const [startTime, setStartTime] =useState();
  const [endTime, setEndTime] =useState();


  interface Event { 
    name: string; 
    description: string;
    eventCategory:number; 
    startTime:string; 
    endTime:string; 
    price:number; 
    numberOfSpots: number;
    venueId:number 
  };

  type ZipCodeDistrict={
    zipcode: number;
    city: string;
  }

  interface Venue {

    venueId:number,
    address:string,
    description: string,
    name:string,
    zipcodeNavigation: ZipCodeDistrict,
    area:string,

  }

  const initialValues:Event = {
    name: "", 
    description: "",
    eventCategory:0, 
    startTime:"", 
    endTime:"",
    price: 0, 
    numberOfSpots:0,
    venueId:0
  };
    
  const eventValidationSchema = Yup.object({
     name: Yup.string().required('Required'),
     description: Yup.string().required('Required'), 
     eventCategory: Yup.number().required('Required'), 
     startTime: Yup.string().required('Required'), 
     endTime: Yup.string().required('Required'), 
     price: Yup.number().required('Required'), 
     numberOfSpots: Yup.number().required('Required'), 
     venueId: Yup.number().required('Required'),
    })

  const queryClient=useQueryClient()

  const mutation = useMutation(newEvent=>addEvent(newEvent),{
      onSuccess:()=>{
        queryClient.invalidateQueries("events")
        props.setOpenClose()
      },
      onError:(error,variables,context)=>{
        alert(error)
        
      }
  });

  const handleSubmit=(data:any)=>{
    mutation.mutate(data)
  }


  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={()=>props.setOpenClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 md:max-w-2xl sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <PlusCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-xl leading-6 font-medium text-gray-900">
                      Add Event
                    </Dialog.Title>
                
                    <div className="mt-2">
                        <div className="space-y-8 divide-y divide-gray-200">
                            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                <div>
                                    <div>
                                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                        Add the the parking spot information by selecting and filling in all the relevand data
                                        </p>
                                    </div>

                                    
                                        <Formik 
                                            initialValues={initialValues} 
                                            onSubmit={(values,{setSubmitting}) =>
                                                {
                                                  console.log(values)
                                                   console.log("Attempting to submit new event", values);
                                                    
                                                   handleSubmit(values)

                                                   mutation.isError? setSubmitting(false):
                                                   mutation.isError? alert('An error occured check log for details'):
                                                   mutation.isLoading? setSubmitting(true):
                                                   mutation.isSuccess? setSubmitting(false): setSubmitting(true);
                                                } 
                                            }
                                            
                                            validationSchema={eventValidationSchema}
                                            
                                        >

                                          {({isSubmitting,submitForm,handleChange,handleBlur, handleSubmit, errors,touched,values,setSubmitting, setFieldValue}) =>(
                                                <Form onSubmit={ (e)=>{
                                                    e.preventDefault();
                                                   
                                                    
                                                    
                                                    }}>

                                                      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                                            <label htmlFor="event-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                                Name
                                                            </label>
                                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                                <Field
                                                                id="event-name"
                                                                name="name"
                                                                type="text"
                                                                className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                                                                placeholder="What is the event name"
                                                                /> 

                                                                {errors.name && touched.name && <div className='text-xs text-red-500'>{errors.name}</div>}   

                                                            </div>
                                                          </div>

                                                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                                            <label htmlFor="event-description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                                Description
                                                            </label>
                                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                                <Field
                                                                  as="textarea"
                                                                  title='event-description'
                                                                  id="event-desctription"
                                                                  name="description"
                                                                  rows={3}
                                                                  className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                                                                  placeholder="Add a few sentences about the event"
                                                                />
                                                                 {errors.description && touched.description && <div className='text-xs text-red-500'>{errors.description}</div>}
                                                            </div>
                                                          </div>

                                                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                                              <label htmlFor="event-category" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                                  Event Category
                                                              </label>
                                                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                                  <div className="sm:col-span-3">
                                                                      <div className="mt-1">
                                                                          <Field
                                                                            as="select"
                                                                            id="event-category"
                                                                            name="eventCategory"
                                                                            type="number"
                                                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                                          >
                                                                            {eventCategories.map((category)=>(
                                                                              <option key={category.id} value={category.id}>{category.name}</option>

                                                                            ))}
                                                                          </Field>
                                                                          {errors.eventCategory && touched.eventCategory && <div className='text-xs text-red-500'>{errors.eventCategory}</div>}
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>

                                                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                                            <label htmlFor="event-start" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                                Start Time
                                                            </label>
                                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                                <Field  name="startTime" type="datetime-local" placeholder={"Start Time"} component={DatepickerField}/>
                                                          

                                                                 
                                                            </div>
                                                          </div>

                                                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                                            <label htmlFor="event-end" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                                End Time
                                                            </label>
                                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                                <Field id="event-end" name="endTime" component={DatepickerField}                                                                /> 
                                                                 {errors.endTime && touched.endTime && <div className='text-xs text-red-500'>{errors.endTime}</div>}
                                                            </div>
                                                          </div>

                                                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                                            <label htmlFor="event-parking-price" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                                Price
                                                            </label>
                                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                                <Field
                                                                id="event-parking-price"
                                                                name="price"
                                                                type="number"
                                                                inputMode='numeric'
                                                                className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                                                                /> 
                                                                 {errors.price && touched.price && <div className='text-xs text-red-500'>{errors.price}</div>}
                                                            </div>
                                                          </div>

                                                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                                              <label htmlFor="event-spots" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                                  Number of Spots
                                                              </label>
                                                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                                  <div className="sm:col-span-3">
                                                                      <div className="mt-1">
                                                                          <Field
                                                                            as="select"
                                                                            id="event-spots"
                                                                            name="numberOfSpots"
                                                                            type="nu"
                                                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                                          >
                                                                            {
                                                                              spots.map((i)=>(
                                                                                <option key={i} value={i}>{i}</option>
                                                                              ))
                                                                            }
                                                                          </Field>
                                                                          {errors.numberOfSpots && touched.numberOfSpots && <div className='text-xs text-red-500'>{errors.numberOfSpots}</div>}
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>

                                                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                                              <label htmlFor="event-venue" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                                  Venue
                                                              </label>
                                                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                                  <div className="sm:col-span-3">
                                                                      <div className="mt-1">

                                                                      <>
                                                                          {isLoading &&  
                                                                            <span>
                                                                              <svg aria-hidden="true" className="mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-400 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                                              </svg>
                                                                            </span>
                                                                          }
                                                                        <Field
                                                                          as="select"
                                                                          title='event-venue'
                                                                          id="event-venue"
                                                                          name="venueId"
                                                                          type="number"
                                                                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                                        >
                                                                          <option key={'empty-venue'}>Select venue</option>
                                                                          
                                                                          {data?.map((venue:Venue) => (
                                                                              <option key={venue?.venueId} value={venue?.venueId}>{ `${venue?.name} ${" at "} ${venue?.address} ${venue?.venueId}` }</option>
                                                                            ))}
                                                                        </Field>

                                                                        {errors.venueId && touched.venueId && <div className='text-xs text-red-500'>{errors.venueId}</div>}

                                                                      </>

                                                                        

                                                                      {/* <RenderVenues validationError={errors.venueId} touched={touched.venueId}/> */}
                                                                        
                                                                          <DisclosureA
                                                                            title={"Add Venue"}
                                                                            content={<AddVenueForm/>}
                                                                          />
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>

                                                      <div className="pt-5">
                                                        <div className="flex justify-end">
                                                          <button
                                                              type="button"
                                                              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                          >
                                                              Cancel
                                                          </button>
                                                          <button
                                                              type="submit"
                                                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                              onClick={submitForm}
                                                              disabled={isSubmitting}
                                                          >
                                                              Save
                                                          </button>
                                                        </div>
                                                    </div>

                                                    </Form>
                                          )}
                                        </Formik>

                                  
                                </div>
                            </div>

                            

                           
                        </div>
                    </div>
                  </div>
                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

// function RenderVenues(props:any) {

  
//   const{validationError,touched}=props;
//   const formikContext=useFormikContext();

//   if (isLoading) {
//     return <span><svg aria-hidden="true" className="mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-400 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
//     <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
// </svg></span>
//   }

//   if (isError) {
//     if(error instanceof Error) {
  
//       return <span>Error: {error.message}</span>
//     }
    
//   }

//   if(data instanceof Array) {
//     return (

     
//     )

//   }

//   return(<></>)

// }


