import React, { Fragment } from 'react'
import * as Yup from 'yup';
import logo from '../../../../assets/logos/logo.svg'

import { Dialog, Transition } from '@headlessui/react'
import { useMutation, useQueryClient } from 'react-query';
import { getData, postData } from '../../../../api';
import { Field, Form, Formik } from 'formik';
import useFetchData from '../../../../hooks/useFetchData';

type Driver={
  driverId:string;
  user:User;
  vehicle:Array<Vehicle>;

}
type User={
  email:string;
  firstName:string;
  lastName:string;
  phoneNumber:string;
  userId:string;
  active:boolean;
  address:string;
  zipcode:number;
  emailConfirmed:boolean;
}

type Vehicle={
  nickName: string,
  licensePlate: string,
  size: number,
  driverId: string

}


export default function AddVehicleModal(props: any) {

    const {isLoading, isError, data,  error} = useFetchData('allDrivers',getData('/Driver/GetAllDrivers'));


  const initialValues:Vehicle = {
    nickName: "",
    size: 0,
    licensePlate: "",
    driverId: ""
  };
    
  const vehicleValidationSchema = Yup.object({
     nickName: Yup.string().required("Required"),
     size:Yup.number().required("Required"),
     licensePlate:Yup.string().required("Required"),
     driverId:Yup.string().required("Required"),

   
    })

  const queryClient=useQueryClient()

  const mutation = useMutation(newCar=>postData(newCar,'/Vehicle/CreateVehicle'),{
      onSuccess:()=>{
        queryClient.invalidateQueries("allDrivers")
        props.setOpenClose()
      },
      onError:(error,variables,context)=>{
        alert(error)
      }
  });

  const handleSubmit=(data:any)=>{
    mutation.mutate(data)
  }


  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={()=>props.setOpenClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 md:max-w-2xl sm:w-full sm:p-6">
                <div>
                  
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-xl leading-6 font-medium text-gray-900">
                      
                    </Dialog.Title>
                
                    <div className="mt-2">
                        <div className="space-y-8 divide-y divide-gray-200">
                            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                <div>
                                    
                                        <Formik 
                                            initialValues={initialValues} 
                                            onSubmit={(values,{setSubmitting}) =>
                                                {
                                                  console.log(values)
                                                   console.log("Attempting to add new car to driver", values);
                                                    
                                                   handleSubmit(values)

                                                   mutation.isError? setSubmitting(false):
                                                   mutation.isError? alert('An error occured check log for details'):
                                                   mutation.isLoading? setSubmitting(true):
                                                   mutation.isSuccess? setSubmitting(false): setSubmitting(true);
                                                } 
                                            }
                                            validationSchema={vehicleValidationSchema}
                                            
                                        >

                                          {({isSubmitting,submitForm,handleChange,handleBlur,errors,touched}) =>(
                                                <Form onSubmit={ (e)=>{
                                                    e.preventDefault();
                                                   
                                                    
                                                    
                                                    }}>

                                                      <div className="flex min-h-full flex-col justify-start py-12 sm:px-6 lg:px-8">
                                                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                                                          <img
                                                              className="mx-auto h-8 w-auto"
                                                              src={logo}
                                                              alt="ParkShare"
                                                          />
                                                          <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900">Select and add new driver</h2>
                                                          <p className="mt-2 text-center text-sm text-gray-600">
                                                            
                                                          </p>
                                                        </div>

                                                        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
                                                          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                                                              <div className="space-y-6">

                                                                <div>
                                                                    <div className='flex justify-items-start'>
                                                                      <label htmlFor="driver-id" className="block text-sm font-medium  text-gray-700">
                                                                      Driver
                                                                      </label>
                                                                    </div>
                                                                    
                                                                    <div className="mt-1">
                                                                          <Field
                                                                            as="select"
                                                                            id="driver-id"
                                                                            name="driverId"
                                                                            type="string"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                                          >
                                                                            <option key="ParkShareDefault" value="ParkShare"> Select a driver</option>
                                                                            {data?.map((driver:Driver)=>(
                                                                              <option key={driver?.user?.userId} value={driver?.driverId}>{`${driver?.user?.firstName} ${driver?.user?.lastName} `} {`${driver.user.email}`} </option>

                                                                            ))}
                                                                          </Field>
                                                                          {errors.driverId && touched.driverId && <div className="text-xs text-red-500">{errors.driverId}</div>}
                                                                      </div>
                                                                </div>
                                                                <div>
                                                                    <div className='flex justify-items-start'>
                                                                      <label htmlFor="license-plate" className="block text-sm font-medium  text-gray-700">
                                                                      License Plate
                                                                      </label>
                                                                    </div>
                                                                    
                                                                    <div className="mt-1">
                                                                      <Field
                                                                        id="license-plate"
                                                                        name="licensePlate"
                                                                        autoCapitalize="true"
                                                                        type="text"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                                      />
                                                                      {errors.licensePlate && touched.licensePlate && <div className="text-xs text-red-500">{errors.licensePlate}</div>}
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className='flex justify-items-start'>
                                                                      <label htmlFor="nick-name" className="block text-sm font-medium  text-gray-700">
                                                                      Nick Name
                                                                      </label>
                                                                    </div>
                                                                    
                                                                    <div className="mt-1">
                                                                      <Field
                                                                        id="nick-name"
                                                                        name="nickName"
                                                                        type="text"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                                      />
                                                                      {errors.nickName && touched.nickName && <div className="text-xs text-red-500">{errors.nickName}</div>}
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className='flex justify-items-start'>
                                                                      <label htmlFor="size" className="block text-sm font-medium  text-gray-700">
                                                                      Size
                                                                      </label>
                                                                    </div>
                                                                    
                                                                    <div className="mt-1">
                                                                          <Field
                                                                            as="select"
                                                                            id="size"
                                                                            name="size"
                                                                            type="number"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                                          >
                                                                            <option key="Size" value="Size"> Select a size of the car</option>
                                                                            <option key="small" value={0}> Small</option>
                                                                            <option key="normal" value={1}> Normal</option>

                                                                          </Field>
                                                                          {errors.size && touched.size && <div className="text-xs text-red-500">{errors.size}</div>}
                                                                      </div>
                                                                </div>
                                                            

                                                                <div>
                                                                  
                                                                    <button
                                                                    type="submit"
                                                                    className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                    onClick={submitForm}
                                                                    disabled={isSubmitting}
                                                                    >
                                                                    Add Vehicle
                                                                    </button>
                                                                </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>

                                                    </Form>
                                          )}
                                        </Formik>

                                  
                                </div>
                            </div>

                            

                           
                        </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}




