import { createColumnHelper } from '@tanstack/react-table';
import React, { useState } from 'react';
import { getData } from "../../../api";
import SmallSpinner from '../../../components/SmallSpinner';
import TableContainer from '../../../components/TableContainer';
import useFetchData from "../../../hooks/useFetchData";
import AddAddressModal from '../AddAddressModal';
import EditAddressModal from '../EditAddressModal';

import AddParkingSpotModal from '../AddParkingSpotModal';




type ZipCodeDistrict={
  zipcode: number;
  city: string;
}

interface Address{
    addressId:number,
    streetName:string,
    streetNumber:string,
    lat:string,
    long: string,
    active:boolean,
    photo: string,
    zipCodeDistrict:ZipCodeDistrict,
    zone:number,
    ownerId:number,
    area:string
}

interface ParkingSpot{
    parkingSpotId:number,
    pSpotActive:boolean,
    description:string,
    photo:string,
    price: number,
    address:number
}
interface AddressItem{
    address:Address,
    parkingSpots:Array<ParkingSpot>
}

export default function AllAddresses() {

  const [isOpenAddAddressModal,setOpenAddAddressModal]=useState(false);
  const [isOpenAddParkingSpotModal,setOpenAddParkingSpotModal]=useState(false);
  const [isOpenEditAddressModal,setOpenEditAddressModal]=useState(false);
  const [selectedAddress, setSelectedAddress]=useState({});
  const {isLoading, isError, data,  error} = useFetchData('addresses',getData('/Address/GetAllAddresses'));
  data?.sort((a:AddressItem,b:AddressItem)=>(a.address.streetName.localeCompare(b.address.streetName) || Number(a.address.streetNumber) -Number(b.address.streetNumber)));
  
  
  // const refreshTable = React.useReducer(() => ({}), {})[1]
  const toggleAddAddressModal=()=>{
    setOpenAddAddressModal(!isOpenAddAddressModal)
  };
  const toggleEditAddressModal=()=>{
    setOpenEditAddressModal(!isOpenEditAddressModal)
  };
  const toggleAddParkingSpotModal=()=>{
    setOpenAddParkingSpotModal(!isOpenAddParkingSpotModal)
  };



  const columnHelper = createColumnHelper<AddressItem>();
  const columns= [
    columnHelper.accessor(row => `${row.address?.streetName} `, {
        id: 'streetName',
        cell: data => 
            <span className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                {data.getValue()}
            </span>,
        header: () => <span>Street Name</span>,
        footer: data => data.column.id,
      }),
      columnHelper.accessor(row => `${row.address?.streetNumber} `, {
        id: 'streetNumber',
        cell: data => 
            <span className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                {data.getValue()}
            </span>,
        header: () => <span>Street Number</span>,
        footer: data => data.column.id,
      }),
    columnHelper.accessor(row => `${row.address?.zipCodeDistrict?.city}`, {
      id: 'city',
      cell: data => 
          <span className="whitespace-nowrap px-3 py-4 text-sm  text-gray-500 hidden sm:table-cell ">
            {data.getValue()}
          </span>,
      header: () => <span>City</span>,
      footer: data => data.column.id,
    }),
    columnHelper.accessor(row => `${row.address?.zipCodeDistrict?.zipcode}`, {
        id: 'zipcode',
        cell: data => 
            <span className="whitespace-nowrap px-3 py-4 text-sm  text-gray-500 hidden sm:table-cell ">
              {data.getValue()}
            </span>,
        header: () => <span>Zip Code</span>,
        footer: data => data.column.id,
      }),

    columnHelper.accessor(row => row.address?.active, {
        id: 'status',
        cell: data => 
            <span className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"> 
              {data.getValue()?
              <>
               <div className="flex flex-row">
                  <div className="flex items-center"> 
                    <span className="flex h-3 w-3">
                      <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-green-400 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                    </span>

                  </div>
                
                  <div>
                    <span className="whitespace-nowrap px-3 text-sm text-gray-500 hidden sm:table-cell ">
                      Active
                    </span>
                  </div>
                </div>
              </>
              : 
              <>
                <div className="flex flex-row">
                  <div className="flex items-center"> 
                    <span className="flex h-3 w-3">
                      <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-amber-400 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-3 w-3 bg-amber-500"></span>
                    </span>

                  </div>
                
                  <div>
                    <span className="whitespace-nowrap px-3 text-sm text-gray-500 hidden sm:table-cell ">
                      Inactive
                    </span>
                  </div>
                </div>
                
              </>
              
              }
            </span>, 
        header: () => <span>Status</span>,
        footer: data => data.column.id,
      }),

      columnHelper.accessor(row => row?.parkingSpots, {
        id: 'spots',
        cell: data => 
            <div className="flex flex-col flex-nowrap items-center">
                <span className="whitespace-nowrap px-3 py-4 text-sm  text-gray-500 hidden sm:table-cell ">
                    {data.getValue().length}
                </span>
            </div>,
        header: () => <span>Spots</span>,
        footer: data => data.column.id,
      }),


    columnHelper.display({
    header:()=><span> Map</span>,
    id: 'map',
    cell: (data: { row: any; }) => (
        <>
            <div className="flex flex-col flex-nowrap items-center">
            
                <a
                        type="button"
                        className="inline-flex items-center px-1 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-indigo-500 bg-white hover:text-indigo-700 underline"
                        href={`${'https://www.google.com/maps/place/'}${data.row.original.address.lat},${data.row.original.address.long}`}
                        target="_blank"
                        rel="noopener noreferrer"
                >
                        View on Map
                </a>

            </div>
            
        </>
    )
    }),

    columnHelper.display({
        header:()=><span> Edit</span>,
        id: 'edit',
        cell: (data: { row: any; }) => (
            <>
                <div className="flex flex-col flex-nowrap items-center">
                
                    <button
                            type="button"
                            className="inline-flex items-center px-1 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-indigo-500 bg-white hover:text-indigo-700"
                            onClick={()=>{
                              setSelectedAddress(data.row.original)
                              toggleEditAddressModal()
                              

                              }
                            }
                    >
                            Edit
                    </button>
    
                </div>
                
            </>
        )
        }),
    

    
    columnHelper.display({
      header:()=><span> Status</span>,
      id: 'action',
      cell: (data => (
        <>
            <div className="flex flex-col items-center">
                <button
                    type="button"
                    className="inline-flex items-center px-2 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={()=>{
                      setSelectedAddress(data.row.original)
                      toggleAddParkingSpotModal()
                      
                    }}
                >
                    Add Spot
                </button>
                

            </div>
           
        </>
      ))
    }),
    
  ]

  
  if(isError){

      console.log(error);
      return(
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto">{"Error fetching data"}</div>
          </div>
        </>
      )
    }
  return(
      <>
        <div className="pt-6">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">All Addresses</h1>
              <p className="mt-2 text-sm text-gray-700">These are all the addresses on which ParkShare has listed parking spots</p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  onClick={toggleAddAddressModal}
              >
                 Add Address
              </button>
            </div>
          </div>
        </div>
        {isLoading?
          <>
            Loading Data <SmallSpinner/>
          </>

          :
          <TableContainer
              title={"All Addresses"} 
              description={"These are all addresses with parking spots registered on ParkShare"} 
              data={data}
              isLoading={isLoading}
              noDataMessage="Address data not found add  parking spots with address data"
              columns={columns}
              />

        }
          

            { isOpenAddAddressModal && 
              <AddAddressModal isOpen={isOpenAddAddressModal} setOpenClose={toggleAddAddressModal}/>
            }
            {
              isOpenEditAddressModal &&
              <EditAddressModal isOpen={isOpenEditAddressModal} setOpenClose={toggleEditAddressModal} address={selectedAddress}/>
            }
            { isOpenAddParkingSpotModal && 
              <AddParkingSpotModal isOpen={isOpenAddParkingSpotModal} setOpenClose={toggleAddParkingSpotModal} selectedAddress={selectedAddress}/>
            }
      </>
  )
    
}