import TabsContainer from "../../../components/TabsContainer";


const tabs = [
    { name: 'Active Events', to: '/event-management/active-events', current: false },
    { name: 'Upcoming Events', to: '/event-management/upcoming-events', current: false },
    { name: 'All Events', to: '/event-management/all-events', current: false },
  ]
  
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  
  export default function EventManagementTabs() {
    
    return (
      <>
        <TabsContainer tabs={tabs}/>
      </>
    )
  }