import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import OwnerManagementTabs from "./OwnerManagementTabs";

export default function OwnerManagement(){
  const { user } = useAuth();
  const navigate = useNavigate()

  
    
    useEffect(() => {
    if (!user) {
      // user is not authenticated
      return navigate('/login');
      }
    }, [user]);
    return(
        <>
            
        <div className="max-w-7xl  px-4 sm:px-6 lg:px-8 pt-6">
          <header>
            <h1 className="text-2xl font-bold leading-tight text-gray-900">Owner Management</h1>
          </header>

          <OwnerManagementTabs/>
          
        </div>
        
        </>
    )
}