import {  NavLink, Outlet } from "react-router-dom"
import TabsContainer, { TabsProps } from "../../../components/TabsContainer";


const tabs = [
    { name: 'All Addresses', to: '/address-management/all-addresses', count: '4', current: true },
  ]
  
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  
  export default function AddressManagementTabs() {
    
    return (
      <>
        <TabsContainer tabs={tabs}/>
      </>
    )
  }