/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PlusCircleIcon } from '@heroicons/react/outline'
import DisclosureA from '../../../components/Disclosure'
import AddAddressForm from './AddAddressForm'
import useFetchData from '../../../hooks/useFetchData'
import { getAllAddresses, addParkingSpot } from '../../../api'
import { Field, Form, Formik } from 'formik'
import { useMutation, useQueryClient } from 'react-query'
import * as Yup from 'yup';



interface ParkingSpot{
  addressId:number,
  description: string,
  spotSize: number,
  hourly:number,
  monthly:number,
}
interface Address{
  addressId:number;
  streetName: string;
  streetNumber: string;
  zipCode: number;
  area: string;
}

interface AddressItem {
  address:Address,

}

// interface Owner {
//   id
//   firstName: string;
//   lastName: string;
//   email: string;
//   address: string;
//   phoneNumber: string;
//   zipCode: number;
//   password:string;
//   confirmPassword: string;
// }


export default function AddParkingModal(props: any) {

  const {isLoading, isError, data,  error} = useFetchData('addresses',getAllAddresses());

  const queryClient=useQueryClient()

  const mutation = useMutation(newParkingSpot=>addParkingSpot(newParkingSpot),{
      onSuccess:()=>{
        queryClient.invalidateQueries("allParkingSpots")
        props.setOpenClose()
      },
      onError:(error,variables,context)=>{
        alert(error)
      }
  });

  const handleSubmit=(data:any)=>{
    mutation.mutate(data)
  }

  const initalValues:ParkingSpot = {addressId: 0, description:"",spotSize:0, hourly:1,  monthly:0};
  const parkingSpotValidationSchema = Yup.object().shape({
      addressId: Yup.number().required('Required'),
      description: Yup.string()
        .min(40, 'Too Short!')
        .max(500, 'Too Long!')
        .required('Required'),
      spotSize: Yup.number().required('Required'),
      hourly: Yup.number().required('Required'),
      monthly: Yup.number().required('Required'),
    });



  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={()=>props.setOpenClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 md:max-w-2xl sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <PlusCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-xl leading-6 font-medium text-gray-900">
                      Add Parking Spot
                    </Dialog.Title>
                
                    <div className="mt-2">
                        <div className="space-y-8 divide-y divide-gray-200">
                            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                <div>
                                    <div>
                                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                        Add the the parking spot information by selecting and filling in all the relevand data
                                        </p>
                                    </div>


                                    <Formik 
                                      initialValues={initalValues} 
                                      validationSchema={parkingSpotValidationSchema}
                                      onSubmit={(values,{setSubmitting})=>{
                                        console.log("Attempting to submit new parking spot", values);
                                                    
                                        handleSubmit(values)

                                        mutation.isError? setSubmitting(false):
                                        mutation.isError? alert('An error occured check log for details'):
                                        mutation.isLoading? setSubmitting(true):
                                        mutation.isSuccess? setSubmitting(false): setSubmitting(true);

                                          

                                      }}
                                    >
                                      {({isSubmitting,submitForm,handleChange,handleBlur,errors,touched,values,setSubmitting}) =>
                                      (
                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                           
                                            }
                                        }>

                                            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                                {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                                    <label htmlFor="owner" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                        Owner
                                                    </label>
                                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                        <div className="sm:col-span-3">
                                                            <div className="mt-1">

                                                            <Field
                                                              as="select"
                                                              id="owner"
                                                              name="owner"
                                                              autoComplete="owner-name"
                                                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                            >
                                                              <option key={7} value={7}>Kelvin 7</option>
                                                                {data.map((owner:Owner) => (
                                                                    <option key={owner.id}>{owner.firstName + ' ' + owner.lastName}</option>
                                                                  ))}
                                                            </Field>
                                                                                                                      
                                                                <DisclosureA
                                                                  title={"Add Owner"}
                                                                  content={<AddOwnerForm/>}
                                                                />

                                                              
                                                            </div>
                                                          
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                                    <label htmlFor="address-id" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                        Address
                                                    </label>
                                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                        <div className="sm:col-span-3">
                                                            <div className="mt-1">

                                                              <Field
                                                                as="select"
                                                                title="address-id"
                                                                id="address-id"
                                                                name="addressId"
                                                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                              >
                                                                  {data?.map((addressItem:AddressItem) => (
                                                                    
                                                                      <option key={addressItem?.address?.addressId} value={addressItem?.address?.addressId}>{addressItem?.address?.streetName + ' ' + addressItem?.address?.streetNumber}</option>
                                                                    ))}
                                                              </Field>
                                                              {errors.addressId && touched.addressId && <div className='text-red-500 text-xs'>{errors.addressId}</div>}

                                                              <DisclosureA
                                                                title={"Add Address"}
                                                                content={<AddAddressForm/>}
                                                              />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                                    <label htmlFor="spot-size" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                        Spot Size
                                                    </label>
                                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                        <div className="sm:col-span-3">
                                                            <div className="mt-1">
                                                                <Field
                                                                  as="select"
                                                                  title="spot-size"
                                                                  id="spot-size"
                                                                  name="spotSize"
                                                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                                  >
                                                                  <option key={"small"} value={0}>Small</option>
                                                                  <option key={"normal"} value={1}>Normal</option>
                                                                </Field>
                                                                {errors.spotSize && touched.spotSize && <div>{errors.spotSize}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                                    <label htmlFor="hourly" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                        Hourly
                                                    </label>
                                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                        <div className="sm:col-span-3">
                                                            <div className="mt-1">
                                                                <Field
                                                                  as="select"
                                                                  title="hourly"
                                                                  id="hourly"
                                                                  name="hourly"
                                                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                                  >
                                                                  <option key={"default"}>Select Yes or No</option>
                                                                  <option key={"yes"} value={1}>Yes</option>
                                                                  <option key={"no"} value={2}>No</option>
                                                                </Field>
                                                                {errors.hourly && touched.hourly && <div>{errors.hourly}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                                    <label htmlFor="monthly" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                        Monthly
                                                    </label>
                                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                        <div className="sm:col-span-3">
                                                            <div className="mt-1">
                                                                <Field
                                                                  as="select"
                                                                  title="monthly"
                                                                  id="monthly"
                                                                  name="monthly"
                                                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                                  >
                                                                  <option key={"default"}>Select Yes or No</option>
                                                                  <option key={"yes"} value={1}>Yes</option>
                                                                  <option key={"no"} value={2}>No</option>
                                                                </Field>
                                                                {errors.hourly && touched.hourly && <div>{errors.hourly}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                
                                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                                  <label htmlFor="spot-description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                      Description
                                                  </label>
                                                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                      <Field
                                                      as="textarea"
                                                      id="spot-description"
                                                      name="description"
                                                      rows={3}
                                                      className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                                                      placeholder="Write a few sentences describing the parking spot e.g where it is and how to access it"
                                                      />
                                                      {errors.description && touched.description && <div>{errors.description}</div>}
                                                  </div>
                                                </div>

                                                {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                                  <label htmlFor="cover-photo" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                      Photo
                                                  </label>
                                                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                      <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                      <div className="space-y-1 text-center">
                                                          <svg
                                                          className="mx-auto h-12 w-12 text-gray-400"
                                                          stroke="currentColor"
                                                          fill="none"
                                                          viewBox="0 0 48 48"
                                                          aria-hidden="true"
                                                          >
                                                          <path
                                                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                              strokeWidth={2}
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                          />
                                                          </svg>
                                                          <div className="flex text-sm text-gray-600">
                                                          <label
                                                              htmlFor="file-upload"
                                                              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                          >
                                                              <span>Upload a file</span>
                                                              <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                                                          </label>
                                                          <p className="pl-1">or drag and drop</p>
                                                          </div>
                                                          <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                                      </div>
                                                      </div>
                                                  </div>
                                                </div> */}
                                                 <div className="pt-5">
                                                      <div className="flex justify-end">
                                                        <button
                                                            type="button"
                                                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                            onClick={()=>props.setOpenClose()}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                            onClick={submitForm}
                                                            disabled={isSubmitting}
                                                        >
                                                            Save
                                                        </button>
                                                      </div>
                                                  </div>

                                            </div>
                                        </Form>
                                      )}
                                    </Formik>

                                  
                                </div>
                            </div>

                            

                           
                        </div>
                    </div>
                  </div>
                </div>
              
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

// function RenderOwners() {

//   const {isLoading, isError, data,  error} = useFetchData('owners',getAllOwners());

//   if (isLoading) {
//     return <span><svg aria-hidden="true" className="mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-400 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
//     <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
// </svg></span>
//   }

//   if (isError) {
//     if(error instanceof Error) {
  
//       return <span>Error: {error.message}</span>
//     }
    
//   }

//   if(data instanceof Array) {
//     return (

     
     
//     )

//   }

//   return(<></>)

// }

// function RenderAddresses() {


//   if (isLoading) {
//     return <span><svg aria-hidden="true" className="mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-400 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
//     <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
// </svg></span>
//   }

//   if (isError) {
//     if(error instanceof Error) {
  
//       return <span>Error: {error.message}</span>
//     }
    
//   }

//   if(data instanceof Array) {
//     return (

    
     
//     )

//   }

//   return(<></>)

// }
