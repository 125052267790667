import { CalendarIcon, ClockIcon, TicketIcon } from '@heroicons/react/solid';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import React from 'react';
import { getData } from "../../../api";
import TableRowActionButton from '../../../components/ParkingsTable/TableRowAction';
import PulseIndicator from '../../../components/PulseIndicator';
import TableContainer from '../../../components/TableContainer';
import useFetchData from "../../../hooks/useFetchData";




type ZipCodeDistrict={
  zipcode: number;
  city: string;
}

type ParkingsTableAddress={
  streetName: string;
  streetNumber: string;
  zipCodeDistrict: ZipCodeDistrict;
}

type ParkingSpot={
  parkingSpotId: number,
  address: ParkingsTableAddress;
  description:string;
  isRentedOut:boolean;
  rentPeriodStart:string;
  rentPeriodEnd:string;
  monthlyRentPeriodType:number;
  
}

export default function AllParkings() {

  const {isLoading, isError, data,  error} = useFetchData('parkingSpots',getData('/ParkingSpot/GetAvailableParkingspots?periodType=1'));
  // const refreshTable = React.useReducer(() => ({}), {})[1]
  const reserveSpot = ()=>{return false}


  const columnHelper = createColumnHelper<ParkingSpot>()
  const columns= [
    columnHelper.accessor(row => `${row.address?.streetName} ${row.address?.streetNumber}`, {
        id: 'address',
        maxSize:150,
        minSize:70,
        cell: data => 
            <span className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 lg:pl-8">
                {data.getValue()}
            </span>,
        header: () => <span>Address</span>,
        footer: data => data.column.id,
      }),
    columnHelper.accessor(row => `${row.address?.zipCodeDistrict?.city} ${row.address?.zipCodeDistrict?.zipcode}`, {
      id: 'area',
      
      cell: data => 
          <span className="whitespace-nowrap px-3 py-4 text-xs  text-gray-500 hidden sm:table-cell ">
            {data.getValue()}
          </span>,
      header: () => <span>Area</span>,
      footer: data => data.column.id,
    }),
    columnHelper.accessor('description', {
      header: () => <span>Description</span>,
      maxSize: 250,
      cell: data =>
            <span className="whitespace-nowrap px-3 py-4 text-xs text-gray-500  truncate">
              {data.getValue()}
            </span>,
      footer: data => data.column.id,
      
    }),
    columnHelper.accessor('rentPeriodStart', {
        id: 'rentPeriodStart',
       
        cell: data => 
            <span className="whitespace-nowrap px-3 py-4 text-xs  text-gray-500 hidden sm:table-cell ">
              {format(new Date(data.getValue()),'MMM, dd, yyyy hh:mm aaa')}
            </span>,
        header: () => <span>Start</span>,
        footer: data => data.column.id,
    }),
    columnHelper.accessor('rentPeriodEnd', {
    id: 'rentPeriodEnd',
   
    cell: data => 
        <span className="whitespace-nowrap px-3 py-4 text-xs  text-gray-500 hidden sm:table-cell ">
            {format(new Date(data.getValue()),'MMM, dd, yyyy hh:mm aaa')}
        </span>,
    header: () => <span>End</span>,
    footer: data => data.column.id,
    }),
    
    columnHelper.accessor('isRentedOut', {
      header: ()=><span> Status</span>,
      cell: data => 
            <span className="whitespace-nowrap py-4 text-right text-sm font-medium"> 
              {data.getValue()?<PulseIndicator color={""} height={""} width={""}/> 
              : 
              <>
                <div className="flex flex-col items-center ">
                    <div className="flex-row">
                        <div className="flex items-center"> 
                            <span className="flex h-3 w-3">
                                <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-green-400 opacity-75"></span>
                                <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                            </span>

                            <div>
                                <span className="whitespace-nowrap pl-3 text-xs text-gray-500 hidden sm:table-cell ">
                                Active
                                </span>
                            </div>
                        </div>
                
                       

                    </div>
                 
                </div>
                
              </>
              
              }
            </span>, 
      footer: data => data.column.id,
    }),
    columnHelper.accessor('monthlyRentPeriodType', {
        id: 'monthlyRentPeriodType',
        cell: data => 
        <span className="whitespace-nowrap py-4 text-right text-xs font-medium"> 
            {data.getValue()
            ?
            
                <>
                    <div className="flex flex-col items-center ">
                        <div className="flex-row">
                            <div className="flex items-center"> 
                               {
                                data.getValue()===3
                                ?
                                    <>
                                        <CalendarIcon className="h-4 text-indigo-500"/>
                                        <div>
                                            <span className="whitespace-nowrap text-xs text-gray-500 hidden sm:table-cell ">
                                                Monthly
                                            </span>
                                        </div>
                                    </>
                                   
                                    
                                :data.getValue()===2
                                ?
                                    <>
                                        <TicketIcon className="h-4 text-indigo-500"/>
                                        <div>
                                            <span className="whitespace-nowrap pl-3 text-xs text-gray-500 hidden sm:table-cell ">
                                                Event
                                            </span>
                                        </div>
                                    </>
                                    
                                :data.getValue()===1
                                ?
                                    <>
                                        <ClockIcon className="h-4 text-indigo-500"/>
                                        <div>
                                            <span className="whitespace-nowrap pl-3 text-xs text-gray-500 hidden sm:table-cell ">
                                                Time
                                            </span>
                                        </div>
                                    </>
                                :
                                null

                                
                               }

                               
                            </div>
                    
                            

                        </div>
                    
                    </div>
                </> 
            : 
               null
            }
        </span>, 
        header: () => <span>Type</span>,
        footer: data => data.column.id,
    }),
    
    columnHelper.display({
      header:()=><span> Action</span>,
      id: 'action',
      cell: (data: { row: any; }) => <TableRowActionButton title={"Reserve"} action={reserveSpot} row={data.row}/>
    }),
    
  ]
  
  if(isError){

      console.log(error);
      return(
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto">{"Error fetching data"}</div>
          </div>
        </>
      )
    }
  return(
      <>
          <TableContainer
              title={"Active Parking Spots"} 
              description={"These are parking spots that are currently occupied"} 
              data={data}
              isLoading={isLoading}
              noDataMessage="No parking spots are currently occupied"
              columns={columns}
              />

            {/* <AddParkingModal/> */}
      </>
  )
    
}