import {
  BriefcaseIcon,
  GlobeIcon,
  HomeIcon,
  PresentationChartLineIcon,
  TicketIcon,
  TruckIcon,
  UserCircleIcon,
  UserGroupIcon,
  UserIcon,
} from '@heroicons/react/outline'

import logo from '../../assets/logos/logo.svg'
import SideBar from './SideBar'
import AnimatedSidebar from './AnimatedSideBar'

const adminNavigation = [
    { name: 'Dashboard', to: '/dashboard', icon: PresentationChartLineIcon, current: true },
    { name: 'Address Management', to: '/address-management', icon: HomeIcon, current: false },
    { name: 'Parking Management', to: '/parking-management', icon: TruckIcon, current: false },
    { name: 'Event Management', to: '/event-management', icon: TicketIcon, current: false },
    { name: 'Owner Management', to: '/owner-management', icon: UserCircleIcon, current: false },
    { name: 'Driver Management', to: '/driver-management', icon: UserCircleIcon, current: false },
    { name: 'User Management', to: '/user-management', icon: UserGroupIcon, current: false },
    { name: 'Admin Profile', to: '/admin-profile', icon: BriefcaseIcon, current: false },
    
  ]
const otherNavigation = [
    { name: 'Visit our website', to: '/website', icon: GlobeIcon, current: true },
    
  ]



export default function SideBars(props: { classNames: (arg0: string, arg1: string) => string | undefined; sidebarOpen: boolean; setSidebarOpen: any }){

    return(
        <>
            <AnimatedSidebar 
                logo={logo} 
                adminNavigation={adminNavigation} 
                otherNavigation={otherNavigation} 
                classNames={props.classNames} 
                sidebarOpen={props.sidebarOpen}
                setSidebarOpen={props.setSidebarOpen}
             />

        {/* Static sidebar for desktop */}

            <SideBar 
                logo={logo} 
                adminNavigation={adminNavigation} 
                otherNavigation={otherNavigation} 
                classNames={props.classNames}
            />
        </>
    )

}