import { ArrowCircleLeftIcon, ArrowCircleRightIcon} from '@heroicons/react/solid';
import { createColumnHelper} from '@tanstack/react-table';
import { format, isPast } from 'date-fns';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { deleteData, getData } from "../../../api";
import SmallSpinner from '../../../components/SmallSpinner';
import TableContainer from '../../../components/TableContainer';
import useFetchData from "../../../hooks/useFetchData";
import AddEventModal from '../AddEventModal';
import EditEventModal from '../EditEventModal';
import AssignEventRentPeriodModal from './AssignEventRentPeriodModal';
import { useMutation, useQueryClient } from 'react-query';



type ZipCodeDistrict={
  zipcode: number;
  city: string;
}

interface Venue {
  venuId:number;
  address: string;
  description:string;
  name:string;
  latitude: string;
  longitude:string;
  zipCodeDistrict:ZipCodeDistrict;
  area:string

}

interface Event {
  eventId: number,
  description:string;
  name:string;
  eventCategory: number;
  price : number;
  startTime:string;
  endTime:string;
  venue :Venue;
  numberOfSpots: number;
  
}

export default function UpcomingEvents() {

  const [selectedEvent,setSelectedEvent]=useState({})
  const [isOpenAddEventModal,setOpenAddEventModal]=useState(false);
  const [isOpenAssignRentPeriodModal,setOpenAssignRentPeriodModal]=useState(false);
  const [isOpenEditEventModal,setOpenEditEventModal]=useState(false);
  const [isOpenViewParkingSpots,setOpenViewParkingSpots]=useState(false);
  const {isLoading, isError, data,  error} = useFetchData('events',getData('/Event/GetAllEvents'));

  const upcomingEvents=data?.filter((event:Event)=> !isPast(new Date(event.startTime)))
  
  // const refreshTable = React.useReducer(() => ({}), {})[1]
  
  const toggleAssignRentPeriodModal=()=>{
    setOpenAssignRentPeriodModal(!isOpenAssignRentPeriodModal)
  };
  const toggleEditEventModal=()=>{
    setOpenEditEventModal(!isOpenEditEventModal)
  };
  const toggleAddEventModal=()=>{
    setOpenAddEventModal(!isOpenAddEventModal)
  };

  const queryClient=useQueryClient()

  const mutation = useMutation(eventId=>deleteData(`/Event?eventId=${eventId}`),{
      onSuccess:()=>{
        queryClient.invalidateQueries("events")
      },
      onError:(error,variables,context)=>{
        alert(error)
        
      }
  });

  const handleSubmit=(data:any)=>{
    mutation.mutate(data)
  }



  const columnHelper = createColumnHelper<Event>();
  const columns= [
    columnHelper.accessor('name', {
        id: 'name',
        maxSize:150,
        minSize:70,
        cell: data => 
            <span className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 lg:pl-8">
                {data.getValue()}
            </span>,
        header: () => <span>Name</span>,
        footer: data => data.column.id,
      }),
      columnHelper.accessor(row=>`${row.venue?.name}`, {
        id: 'Venue',
        maxSize:150,
        minSize:70,
        cell: data => 
            <span className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 lg:pl-8">
                {data.getValue()}
            </span>,
        header: () => <span>Venue</span>,
        footer: data => data.column.id,
      }),
      
    columnHelper.accessor(row => `${row.venue?.zipCodeDistrict?.city} ${row.venue?.zipCodeDistrict?.zipcode}`, {
      id: 'area',
      
      cell: data => 
          <span className="whitespace-nowrap px-3 py-4 text-xs  text-gray-500 hidden sm:table-cell ">
            {data.getValue()}
          </span>,
      header: () => <span>Area</span>,
      footer: data => data.column.id,
    }),

    columnHelper.accessor('startTime', {
        id: 'startTime',
       
        cell: data => 
            <span className="whitespace-nowrap px-3 py-4 text-xs  text-gray-500 hidden sm:table-cell ">
              {format(new Date(data.getValue()),'MMM, dd, yyyy HH:mm')}
            </span>,
        header: () => <span>Start</span>,
        footer: data => data.column.id,
    }),
    columnHelper.accessor('endTime', {
    id: 'endTime',
   
    cell: data => 
        <span className="whitespace-nowrap px-3 py-4 text-xs  text-gray-500 hidden sm:table-cell ">
            {format(new Date(data.getValue()),'MMM, dd, yyyy HH:mm')}
        </span>,
    header: () => <span>End</span>,
    footer: data => data.column.id,
    }),
    columnHelper.accessor('endTime', {
      id: 'status',
     
      cell: data => 
          <span className="whitespace-nowrap px-2 py-4 text-xs  text-gray-500 hidden sm:table-cell ">
              {
                isPast(new Date(data.getValue()))
                ? 
                  <>
                    <ArrowCircleLeftIcon className="h-4"/> Past
                  </>
                : <>
                    <ArrowCircleRightIcon className="h-4"/> Upcoming
                  </>}
          </span>,
      header: () => <span>Status</span>,
      footer: data => data.column.id,
      }),
      columnHelper.accessor('numberOfSpots', {
        id: 'Spots',
        cell: data => 
            <span className="whitespace-nowrap py-4 px-2 text-xs font-medium text-gray-900 sm:pl-6 lg:pl-8">
                {data.getValue()}
            </span>,
        header: () => <span>Spots</span>,
        footer: data => data.column.id,
      }),
    
    // columnHelper.accessor('isRentedOut', {
    //   header: ()=><span> Status</span>,
    //   cell: data => 
    //         <span className="whitespace-nowrap py-4 text-right text-sm font-medium"> 
    //           {data.getValue()?<PulseIndicator color={""} height={""} width={""}/> 
    //           : 
    //           <>
    //             <div className="flex flex-col items-center ">
    //                 <div className="flex-row">
    //                     <div className="flex items-center"> 
    //                         <span className="flex h-3 w-3">
    //                             <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-green-400 opacity-75"></span>
    //                             <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
    //                         </span>

    //                         <div>
    //                             <span className="whitespace-nowrap pl-3 text-xs text-gray-500 hidden sm:table-cell ">
    //                             Active
    //                             </span>
    //                         </div>
    //                     </div>
                
                       

    //                 </div>
                 
    //             </div>
                
    //           </>
              
    //           }
    //         </span>, 
    //   footer: data => data.column.id,
    // }),
    // columnHelper.accessor('eventCategory', {
    //     id: 'monthlyRentPeriodType',
    //     cell: data => 
    //     <span className="whitespace-nowrap py-4 text-right text-xs font-medium"> 
    //         {data.getValue()
    //         ?
            
    //             <>
    //                 <div className="flex flex-col items-center ">
    //                     <div className="flex-row">
    //                         <div className="flex items-center"> 
    //                            {
    //                             data.getValue()==3
    //                             ?
    //                                 <>
    //                                     <CalendarIcon className="h-4 text-indigo-500"/>
    //                                     <div>
    //                                         <span className="whitespace-nowrap text-xs text-gray-500 hidden sm:table-cell ">
    //                                             Monthly
    //                                         </span>
    //                                     </div>
    //                                 </>
                                   
                                    
    //                             :data.getValue()==2
    //                             ?
    //                                 <>
    //                                     <TicketIcon className="h-4 text-indigo-500"/>
    //                                     <div>
    //                                         <span className="whitespace-nowrap pl-3 text-xs text-gray-500 hidden sm:table-cell ">
    //                                             Event
    //                                         </span>
    //                                     </div>
    //                                 </>
                                    
    //                             :data.getValue()==1
    //                             ?
    //                                 <>
    //                                     <ClockIcon className="h-4 text-indigo-500"/>
    //                                     <div>
    //                                         <span className="whitespace-nowrap pl-3 text-xs text-gray-500 hidden sm:table-cell ">
    //                                             Time
    //                                         </span>
    //                                     </div>
    //                                 </>
    //                             :
    //                             null

                                
    //                            }

                               
    //                         </div>
                    
                            

    //                     </div>
                    
    //                 </div>
    //             </> 
    //         : 
    //            null
    //         }
    //     </span>, 
    //     header: () => <span>Type</span>,
    //     footer: data => data.column.id,
    // }),
    
    columnHelper.display({
      header:()=><span> Action</span>,
      id: 'edit',
      cell: (data: { row: any; }) => (
        <>
            <div className="flex flex-col flex-nowrap items-center">
           
                  <button
                        type="button"
                        className="inline-flex items-center px-1 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-indigo-500 bg-white hover:text-indigo-700"
                        onClick={()=>{
                          setSelectedEvent(data.row.original)
                          toggleEditEventModal()}}
                  >
                        Edit
                  </button>

            </div>
           
        </>
      )
    }),
    columnHelper.display({
      header:()=><span> Action</span>,
      id: 'remove',
      cell: (data: { row: any; }) => (
        <>
            <div className="flex flex-col flex-nowrap items-center">
    
                  <Link
                        to={`/event-parkings/${data.row.original.eventId}/${encodeURIComponent(data.row.original.name)}/${encodeURIComponent(data.row.original.venue.address)}/${data.row.original.price}/${data.row.original.eventCategory}`}
                        type="button"
                        className="inline-flex items-center px-1 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-indigo-500 bg-white hover:text-indigo-700"
                      
                  >
                        View Spots
                  </Link>
            </div>
           
        </>
      )
    }),
    columnHelper.display({
      header:()=><span> Action</span>,
      id: 'assign',
      cell: (data => (
        <>
            <div className="flex flex-col items-center">
                <button
                    type="button"
                    className="inline-flex items-center px-2 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={()=>{
                      setSelectedEvent(data.row.original)
                      toggleAssignRentPeriodModal()
                      
                    }}
                >
                    Assign Spots
                </button>
                

            </div>
           
        </>
      ))
    }),
    columnHelper.display({
      header:()=><span> Action</span>,
      id: 'delete',
      cell: (data => (
        <>
            <div className="flex flex-col items-center">
                <button
                    type="button"
                    className="inline-flex items-center px-2 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={()=>{
                      handleSubmit(data.row.original.eventId)
                      
                    }}
                >
                    Delete
                </button>
                

            </div>
           
        </>
      ))
    }),
    
  ]
  
  if(isError){

      console.log(error);
      return(
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto">{"Error fetching data"}</div>
          </div>
        </>
      )
    }
  return(
      <>
        <div className="pt-6">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Upcoming  Events</h1>
              <p className="mt-2 text-sm text-gray-700">These are all upcoming events that we offer parking for</p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  onClick={toggleAddEventModal}
              >
                 Add Event
              </button>
            </div>
          </div>
        </div>

        {
          isLoading?
          <>
            Loading Data <SmallSpinner/>
          </>
          :
          <>
             <TableContainer
              title={"Upcoming Events"} 
              description={"These are the upcoming events that we offer parking for"} 
              data={upcomingEvents}
              isLoading={isLoading}
              noDataMessage="There are no upcoming events click on add event to add more"
              columns={columns}
              />
          </>
        }

        { isOpenAddEventModal && 
          <AddEventModal isOpen={isOpenAddEventModal} setOpenClose={toggleAddEventModal}/>
        }
        
        { isOpenAssignRentPeriodModal && 
          <AssignEventRentPeriodModal isOpen={isOpenAssignRentPeriodModal} setOpenClose={toggleAssignRentPeriodModal} event={selectedEvent}/>
        }
        { isOpenEditEventModal && 
          <EditEventModal isOpen={isOpenEditEventModal} setOpenClose={toggleEditEventModal} event={selectedEvent}/>
        }
        
      </>
  )
    
}