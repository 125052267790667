import React, { Fragment } from 'react'
import * as Yup from 'yup';
import logo from '../../../assets/logos/logo.svg'

import { Dialog, Transition } from '@headlessui/react'
import { useMutation, useQueryClient } from 'react-query';
import { editUser, patchData, postData } from '../../../api';
import { compare } from 'fast-json-patch';

import { Field, Form, Formik } from 'formik';


export default function EditUserModal(props: any) {
  const{user}=props;
  interface User { 
    userId:string;
    email: string; 
    address:string; 
    firstName:string; 
    lastName:string; 
    phoneNumber: string;
    zipcode:number 
  };

  const initialValues:User = {
    userId:user.userId,
    email: user.email,
    address:user.address, 
    firstName:user.firstName,
    lastName: user.lastName, 
    phoneNumber:user.phoneNumber,
    zipcode:user.zipcode,
  };
    
  const userValidationSchema = Yup.object({
     email: Yup.string().email().required("Required"),
     firstName: Yup.string().required('Required'), 
     lastName: Yup.string().required('Required'), 
     phoneNumber: Yup.string().required('Required'), 
     zipcode: Yup.number().required('Required'), 
    })



  const queryClient=useQueryClient()

  const mutation = useMutation(updatedDetails=>editUser(updatedDetails),{
      onSuccess:()=>{
        queryClient.invalidateQueries("users")
        props.setOpenClose()
      },
      onError:(error,variables,context)=>{
        alert(error)
      }
  });

  const handleSubmit=(data:any)=>{
    mutation.mutate(data)
  }

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={()=>props.setOpenClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 md:max-w-2xl sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-xl leading-6 font-medium text-gray-900">
                     
                    </Dialog.Title>
                
                    <div className="mt-2">
                        <div className="space-y-8 divide-y divide-gray-200">
                            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                <div>
                                        <Formik 
                                            initialValues={initialValues} 
                                            onSubmit={(values,{setSubmitting}) =>
                                                {
                                                 
                                                  
                                                  //const patchDoc =compare(initialValues,values) 
                                                  const formData= new FormData();

                                                  formData.append('UserID',values.userId)
                                                  formData.append('Email',values.email)
                                                  formData.append('Address',values.address)
                                                  formData.append('FirstName',values.firstName)
                                                  formData.append('LastName',values.lastName)
                                                  formData.append('PhoneNumber',values.phoneNumber)
                                                  formData.append('Zipcode',values.zipcode.toString())
                                                  console.log("Attempting to edit user", formData);
                                              
                                                  handleSubmit(formData)
                                                    
                                                  
                                                   mutation.isError? setSubmitting(false):
                                                   mutation.isError? alert('An error occured check log for details'):
                                                   mutation.isLoading? setSubmitting(true):
                                                   mutation.isSuccess? setSubmitting(false): setSubmitting(true);
                                                } 
                                            }
                                            validationSchema={userValidationSchema}
                                            
                                        >

                                          {({isSubmitting,submitForm,handleChange,handleBlur, handleSubmit, errors,touched,values,setSubmitting}) =>(
                                                <Form onSubmit={ (e)=>{
                                                    e.preventDefault();
                                                   
                                                    
                                                    
                                                    }}>

                                                      <div className="flex min-h-full flex-col justify-start py-12 sm:px-6 lg:px-8">
                                                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                                                          <img
                                                              className="mx-auto h-8 w-auto"
                                                              src={logo}
                                                              alt="ParkShare"
                                                          />
                                                          <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900">Edit user</h2>
                                                          <p className="mt-2 text-center text-sm text-gray-600">
                                                            
                                                          </p>
                                                        </div>

                                                        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
                                                          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                                                              <div className="space-y-6">

                                                              <div>
                                                                  <div className='flex justify-items-start'>
                                                                    <label htmlFor="user-id" className="block text-sm font-medium  text-gray-700">
                                                                    User ID
                                                                    </label>
                                                                  </div>
                                                                    
                                                                    <div className="mt-1">
                                                                      <Field
                                                                          as="input"
                                                                          id="user-id"
                                                                          name="userId"
                                                                          type="text"
                                                                          onChange={handleChange}
                                                                          onBlur={handleBlur}
                                                                          required
                                                                          disabled
                                                                          className="block w-full appearance-none rounded-md border border-gray-300 bg-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                      />
                                                                       {errors.userId && touched.userId && <div className="text-sm text-red-500">{errors.userId}</div>}
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                  <div className='flex justify-items-start'>
                                                                    <label htmlFor="first-name" className="block text-sm font-medium  text-gray-700">
                                                                    First Name
                                                                    </label>
                                                                  </div>
                                                                    
                                                                    <div className="mt-1">
                                                                      <Field
                                                                          as="input"
                                                                          id="first-name"
                                                                          name="firstName"
                                                                          type="text"
                                                                          onChange={handleChange}
                                                                          onBlur={handleBlur}
                                                                          autoComplete="given-name"
                                                                          required
                                                                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                      />
                                                                       {errors.firstName && touched.firstName && <div className="text-sm text-red-500">{errors.firstName}</div>}
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                  <div className="flex justify-items-start">
                                                                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                                                      Last Name
                                                                    </label>
                                                                  </div>
                                                                   
                                                                    <div className="mt-1">
                                                                      <Field
                                                                          as="input"
                                                                          id="last-name"
                                                                          name="lastName"
                                                                          type="text"
                                                                          onChange={handleChange}
                                                                          onBlur={handleBlur}
                                                                          autoComplete="additional-name"
                                                                          required
                                                                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                      />
                                                                       {errors.lastName && touched.lastName && <div className="text-sm text-red-500">{errors.lastName}</div>}
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                  <div className="flex justify-items-start">
                                                                    <label htmlFor="phone-number" className="block text-sm font-medium text-gray-700">
                                                                      Phone number
                                                                    </label>
                                                                  </div>
                                                                   
                                                                    <div className="mt-1">
                                                                      <Field
                                                                          as="input"
                                                                          id="phone-number"
                                                                          name="phoneNumber"
                                                                          type="tel"
                                                                          autoComplete="tel"
                                                                          onChange={handleChange}
                                                                          onBlur={handleBlur}
                                                                          required
                                                                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                      />
                                                                       {errors.phoneNumber && touched.phoneNumber && <div className="text-sm text-red-500">{errors.phoneNumber}</div>}
                                                                    </div>
                                                                </div>



                                                                <div>
                                                                  <div className="flex justify-items-start">
                                                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                                      Email address
                                                                    </label>
                                                                  </div>
                                                                   
                                                                    <div className="mt-1">
                                                                      <Field
                                                                          as="input"
                                                                          id="email"
                                                                          name="email"
                                                                          type="email"
                                                                          autoComplete="email"
                                                                          onChange={handleChange}
                                                                          onBlur={handleBlur}
                                                                          required
                                                                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                      />
                                                                       {errors.email && touched.email && <div className="text-sm text-red-500">{errors.email}</div>}
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                  <div className="flex justify-items-start">
                                                                    <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                                                      Address
                                                                    </label>
                                                                  </div>
                                                                   
                                                                    <div className="mt-1">
                                                                      <Field 
                                                                          as="input"
                                                                          id="address"
                                                                          name="address"
                                                                          type="text"
                                                                          onChange={handleChange}
                                                                          onBlur={handleBlur}
                                                                          autoComplete="street-address"
                                                                          required
                                                                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                      />
                                                                       {errors.address && touched.address && <div className="text-sm text-red-500">{errors.address}</div>}
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                  <div className="flex justify-items-start">
                                                                    <label htmlFor="zipcode" className="block text-sm font-medium text-gray-700">
                                                                      Zipcode
                                                                    </label>
                                                                  </div>
                                                                   
                                                                    <div className="mt-1">
                                                                      <Field 
                                                                          as="input"
                                                                          inputMode='numeric'
                                                                          id="zipcode"
                                                                          name="zipcode"
                                                                          type="number"
                                                                          onChange={handleChange}
                                                                          onBlur={handleBlur}
                                                                          autoComplete="postal-code"
                                                                          required
                                                                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                      />
                                                                       {errors.address && touched.address && <div className="text-sm text-red-500">{errors.address}</div>}
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                  
                                                                    <button
                                                                    type="submit"
                                                                    className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                    onClick={submitForm}
                                                                    disabled={isSubmitting}
                                                                    >
                                                                    Edit User
                                                                    </button>
                                                                </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>

                                                    </Form>
                                          )}
                                        </Formik>

                                  
                                </div>
                            </div>

                            

                           
                        </div>
                    </div>
                  </div>
                </div>
               
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}




