import { Field, Form, Formik} from "formik";
import { addAddress, addVenue, postData } from "../../../api";
import usePostData from "../../../hooks/usePostData";
import * as Yup from 'yup';
import { useMutation, useQueryClient } from "react-query";


interface Address{
    name: string;
    address: string;
    description: string;
    zipCode: number;
    area: string;
}

export default function AddVenueForm(){

    const initialValues:Address = {name: "",address: "", description: "", zipCode: 0, area:""};
    const addressValidationSchema = Yup.object({
        name: Yup.string()
         .required('Required'),
        address: Yup.string()
         .required('Required'),
        description: Yup.string()
        .required('Required'),
        zipCode: Yup.number().required('Required'),
        Area: Yup.string().required('Required'),

    });

    const queryClient=useQueryClient()

    const mutation = useMutation(newVenue=>postData(newVenue,'/Venue'),{
        onSuccess:()=>{
          queryClient.invalidateQueries("venues")
          alert("Venue added successfully close the Venue Form and continue")
        }
    });
  
    const handleSubmit=(data:any)=>{
      mutation.mutate(data)
    }

    
 
    return (
        <>
            <div className={'pt-4'}>
                <div className="grid justify-items-start">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">Venue Details</h4>
                    <p className="mt-1 text-sm text-gray-500">You are creating a new venue, enter the details below.</p>
                    </div>

                    <Formik 
                        initialValues={initialValues} 
                        onSubmit={(values,{setSubmitting}) =>
                            {
                                console.log(values)
                                console.log("Attempting to submit new venue", values);
                                 
                                handleSubmit(values)

                                mutation.isError? setSubmitting(false):
                                mutation.isError? alert('An error occured check log for details'):
                                mutation.isLoading? setSubmitting(true):
                                mutation.isSuccess? setSubmitting(false): setSubmitting(true);
                               
                            } 
                        }
                        validationSchema={addressValidationSchema}
                        
                    >

                       {({isSubmitting,submitForm,handleChange,handleBlur,errors,touched,values,setSubmitting}) =>(
                            <Form onSubmit={ (e)=>{
                                 e.preventDefault();
                                 console.log("Attempting to submit new venue", values);
                                
                               
                                 // eslint-disable-next-line react-hooks/rules-of-hooks
                                 const mutation = usePostData(addVenue(values));
                                 mutation.isError? setSubmitting(false):
                                 mutation.isError? alert('An error occured check log for details'):
                                 mutation.isLoading? setSubmitting(true):
                                 mutation.isSuccess? setSubmitting(false): setSubmitting(true);
                                 
                                
                                } }>
                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-3 grid justify-items-start">
                                        <label htmlFor="venue-name" className="block text-sm font-medium text-gray-700">
                                        Venue name
                                        </label>
                                        <div className="mt-1">
                                        <Field
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type="text"
                                            name="name"
                                            id="venue-name"
                                            placeholder="Name"
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                        {errors.name && touched.name && <div>{errors.name}</div>}
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3 grid justify-items-start">
                                        <label htmlFor="venue-address" className="block text-sm font-medium text-gray-700">
                                        Address
                                        </label>
                                        <div className="mt-1">
                                        <input
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type="text"
                                            name="address"
                                            id="venue-address"
                                            placeholder="address"
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                        {errors.address && touched.address && <div>{errors.address}</div>}

                                        </div>
                                    </div>
                                    <div className="sm:col-span-6 grid justify-items-start">
                                        <label htmlFor="venue-description" className="block text-sm font-medium text-gray-700">
                                        Description
                                        </label>
                                        <div className="mt-1 w-full">
                                            <Field
                                                as="textarea"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="description"
                                                id="venue-description"
                                                placeholder="What is this venue?"
                                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                            />
                                            {errors.description && touched.description && <div>{errors.description}</div>}

                                        </div>
                                    </div>

                                    <div className="sm:col-span-3 grid justify-items-start">
                                        <label htmlFor="venue-zipCode" className="block text-sm font-medium text-gray-700">
                                        ZIP Code
                                        </label>
                                        <div className="mt-1">
                                        <Field
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type="number"
                                            inputMode="numeric"
                                            name="zipCode"
                                            id="venue-zipCode"
                                            autoComplete="postal-code"
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                        {errors.zipCode && touched.zipCode && <div>{errors.zipCode}</div>}

                                        </div>
                                    </div>

                                    <div className="sm:col-span-3 grid justify-items-start">
                                        <label htmlFor="venue-area" className="block text-sm font-medium text-gray-700">
                                        Area
                                        </label>
                                        <div className="mt-1">
                                        <Field
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type="text"
                                            name="area"
                                            id="venue-area"
                                            autoComplete="address-level2"
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                        {errors.area && touched.area && <div>{errors.area}</div>}

                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-end mt-4">
                                
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Save
                                    </button>
                                </div>

                            </Form>
                        )}
                        
                    </Formik>
                    
                 
            </div>

        </>
    )
}