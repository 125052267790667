import { Field, Form, Formik} from "formik";
import { addAddress, getData, patchData } from "../../../api";
import * as Yup from 'yup';
import { compare } from 'fast-json-patch';

import { useMutation, useQueryClient } from 'react-query'
import useFetchData from "../../../hooks/useFetchData";



interface Address{
    addressId?:number
    ownerId?:string;
    streetName: string;
    streetNumber: string;
    zipCodeDistrict?: ZipCodeDistrict;
    area: string;
}

interface ZipCodeDistrict{
    zipcode:number;
    city:string;
}

interface Owner{
    ownerId: string,
    accountNo:string,
    regNo:string,
    user:User
    addresses:Array<Address>
   
}


type User={
    email:string;
    firstName:string;
    lastName:string;
    phoneNumber:string;
    userId:string;
    active:boolean;
    address:string;
    zipcode:number;
    emailConfirmed:boolean;
}

export default function EditAddressForm(props:any){

    const {owner, selectedAddress} = props
    // const zipCodeDistricts = useFetchData('allDrivers',getData('/Driver/GetAllDrivers'));
    console.log(selectedAddress.address.addressId)

    const {isLoading, isError, data,  error} = useFetchData('allOwners',getData('/Owner/GetAllOwners'));


    

    const initialValues:Address = { streetName: selectedAddress?.address?.streetName|| "", streetNumber: selectedAddress?.address?.streetNumber|| "", area:selectedAddress?.address?.area || ""};
    const addressValidationSchema = Yup.object({
      // ownerId: Yup.string().required('Required'),
       streetName: Yup.string().required('Required'),
       streetNumber: Yup.number().required('Required'),
    //    zipCodeDistrict: Yup.object().required('Required'),
       area: Yup.string().required('Required'),

    });

    const queryClient=useQueryClient()

    const mutation = useMutation(updatedDetails=>patchData(updatedDetails,selectedAddress?.address?.addressId,'/Address?addressid='),{
        onSuccess:()=>{
          queryClient.invalidateQueries("addresses")
          props.toggleModal()

        },
        onError:(error)=>{
            console.log(error)
            
            alert(error)
        }
    });
  
    const handleSubmit=(data:any)=>{
        console.log("Attempting Patch")
      mutation.mutate(data)
    }
  

    
 
    return (
        <>
            <div className={'pt-6'}>
                <div className="grid justify-items-start">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">Address Details</h4>
                    <p className="mt-1 text-sm text-gray-500">You are edditing this address, enter the details below.</p>
                    </div>

                    <Formik 
                        initialValues={initialValues} 
                        onSubmit={(values,{setSubmitting})=>{
                            console.log("Attempting to submit updated address details", values);
                            const patchDoc =compare(initialValues,values)              
                            handleSubmit(patchDoc)

                            mutation.isError? setSubmitting( false):
                            mutation.isError? alert('An error occured check log for details'):
                            mutation.isLoading? setSubmitting(true):
                            mutation.isSuccess? setSubmitting(false): setSubmitting(true);

                              

                          }}
                        validationSchema={addressValidationSchema}
                        
                    >

                       {({isSubmitting,submitForm,handleChange,handleBlur,errors,touched,values,setSubmitting}) =>(
                            <Form onSubmit={ (e)=>{
                                 e.preventDefault();
                                 
                                } 
                            }>
                                {
                                    owner?
                                    <>
                                        
                                    </>
                                    :
                                    <>
                                    </>
                                }

                                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                        {/* */}
                                        
                                        <div className="sm:col-span-3 grid justify-items-start">
                                            <label htmlFor="street-name" className="block text-sm font-medium text-gray-700">
                                            Street Name
                                            </label>
                                            <div className="mt-1 w-full">
                                            <Field
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                name="streetName"
                                                title="street-name"
                                                id="street-name"
                                                autoComplete="address-line1"
                                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                            />
                                            {errors.streetName && touched.streetName && <div className="text-sm text-red-500">{errors.streetName}</div>}
                                            </div>
                                        </div>

                                        <div className="sm:col-span-3 grid justify-items-start">
                                            <label htmlFor="street-number" className="block text-sm font-medium text-gray-700">
                                            Street Number
                                            </label>
                                            <div className="mt-1 w-full">
                                            <Field
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="number"
                                                name="streetNumber"
                                                id="street-number"
                                                title="street-number"
                                                autoComplete="address-line1"
                                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                            />
                                            {errors.streetNumber && touched.streetNumber && <div className="text-sm text-red-500">{errors.streetNumber}</div>}

                                            </div>
                                        </div>

                                        {/* <div className="sm:col-span-3 grid justify-items-start">
                                            <label htmlFor="zip-code" className="block text-sm font-medium text-gray-700">
                                            ZIP Code
                                            </label>
                                            <div className="mt-1 w-full">
                                            <Field
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="number"
                                                name="zipCode"
                                                id="zip-code"
                                                autoComplete="postal-code"
                                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                            />
                                            {errors.zipCodeDistrict && touched.zipCodeDistrict && <div className="text-sm text-red-500">{"error"}</div>}

                                            </div>
                                        </div> */}

                                        <div className="sm:col-span-3 grid justify-items-start">
                                            <label htmlFor="area" className="block text-sm font-medium text-gray-700">
                                            Area
                                            </label>
                                            <div className="mt-1 w-full">
                                            <Field
                                                onChange={handleChange}
                                                type="text"
                                                name="area"
                                                id="area"
                                                autoComplete="address-level2"
                                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                            />
                                            {errors.area && touched.area && <div className="text-sm text-red-500">{errors.area}</div>}

                                            </div>
                                        </div>
                                        </div>
                               
                                <div className="flex justify-end mt-4">
                                
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Update Address
                                    </button>
                                </div>

                            </Form>
                        )}
                        
                    </Formik>
                    
                 
            </div>

        </>
    )
}