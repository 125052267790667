import {  NavLink, Outlet } from "react-router-dom"
import TabsContainer, { TabsProps } from "../../../components/TabsContainer";
import { useEffect } from "react";


const tabs = [
    { name: 'All Users', to: '/user-management/all-users', current: false },
  ]
  
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  
  export default function UserManagementTabs() {
    
    return (
      <>
        <TabsContainer tabs={tabs}/>
      </>
    )
  }