import {
  flexRender, 
  getCoreRowModel,
  getSortedRowModel, 
  SortingState,
  useReactTable } from "@tanstack/react-table";
import React, {useEffect, useState } from "react";



export interface TableContent{
    title: string;
    description: string;
    data: Array<any>;
    isLoading:boolean;
    columns: Array<any>
    noDataMessage:string
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

export default function TableContainer(props: TableContent){

  const [sorting, setSorting] = React.useState<SortingState>([])
  const [tableData, setTableData] = useState(props.data? props.data :[]);
  const{columns, noDataMessage}=props;

  useEffect(() => {
    setTableData(props.data);
  }, [props.data])



  const table = useReactTable({
    data: tableData,
    columns: columns,
    state:{
      sorting,
    },
  
    onSortingChange:setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  
  console.log("Trying this",table?.getCoreRowModel())




    return (
        <>
          <div className="pt-6">
            <div className="mt-4 flex flex-col">
              <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="shadow-sm ring-1 ring-black ring-opacity-5">

                    {
                      tableData?.length>0?

                      <table className="min-w-full table-fixed" >
                        <thead className="bg-gray-50">

                          {table?.getHeaderGroups().map(headerGroup => (
                              <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                  <th 
                              
                                    key={header.id} 
                                    scope="col"
                                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                    >

                                    {header.isPlaceholder
                                      ? null
                                      :(
                                        <div
                                        {...{
                                          className: header.column.getCanSort()
                                            ? 'cursor-pointer select-none'
                                            : '',
                                          onClick: header.column.getToggleSortingHandler(),
                                        }}
                                        >
                                          {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                          )}
                                          {{
                                            asc: ' 🔼',
                                            desc: ' 🔽',
                                          }[header.column.getIsSorted() as string] ?? null}

                                        </div>

                                      )}
                                  </th>
                                ))}
                              </tr>
                          ))}
                      
                        </thead>
                        <tbody className="bg-white">

                          {table.getRowModel().rows?.map((row,rowIndex) => (
                              <tr key={rowIndex}>
                                {row.getVisibleCells().map(cell => (
                                  <td key={cell.id }
                                    className={classNames(
                                      rowIndex !== table.getRowModel().rows?.length - 1 ? 'border-b border-gray-200 truncate overflow-hidden ' : 'truncate overflow-hidden'
                                    )}
                                    {...{ style: { maxWidth: cell.column.columnDef.maxSize}}}
                                  
                                  >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                  </td>
                                ))}
                              </tr>
                          ))}
                        
                        </tbody>
                      </table>

                      :

                      <div className="flex flex-col items center">
                        <p className="ml-8">{noDataMessage}</p>
                      </div>
                    }
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      
      )
}
