

import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import Routing from './components/Routing';

const queryClient = new QueryClient();
function App() {

  return (
    
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <Routing/>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
   
    
  );
}

export default App;
