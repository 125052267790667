import TabsContainer from "../../../components/TabsContainer"

export default function DriverManagementTabs(){
    const tabs = [
        { name: 'All Drivers', to: '/driver-management/all-drivers', current: false },
        // { name: 'Active Drivers', to: '/driver-management/active-drivers', current: false },
        // { name: 'All Events', to: '/event-management/all-events', count: '4', current: false },
      ]
    return(
        <>
             <TabsContainer tabs={tabs}/>
        </>
    )
}