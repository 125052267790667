import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid';
import { ReactNode } from 'react';


interface DisclosureContents{
    title: string;
    content: ReactNode;
}


export default function DisclosureA(props: DisclosureContents) {
  return (
    <div className="w-full  ">
      <div className="mx-auto w-full max-w-md rounded-2xl bg-white mt-2">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>{props.title}</span>
                <ChevronDownIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-2 pb-2 text-sm text-gray-500 border rounded-md mt-2">
               {open && props.content}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  )
}
