import Datepicker,{ registerLocale } from "react-datepicker";
import { CalendarIcon } from "@heroicons/react/outline";
import en from "date-fns/locale/en-GB";

registerLocale("en-GB",en)
const DatepickerField = ({
  field, 
  form, 
  ...props
}: {field: any, form:any, props:any}) => (
  // OR const { setFieldValue } = form;
  // OR const { value, name } = field;
  <div>
    <Datepicker
      {...field}
      selected={field.value}
      showTimeSelect
      locale="en-GB"
      timeFormat="HH:mm"
      dateFormat="d MMMM, yyyy HH:mm"
      showIcon
      icon={CalendarIcon}
      timeIntervals={15}
      onChange={(val) => form.setFieldValue(field.name, val)}
      className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'

    />
  </div>
);
export default DatepickerField;